export const SELECT_PHOTOGRAPHER_LIST = 'SELECT_PHOTOGRAPHER_LIST'
export const selectPhotographerListAction = (photographerState) =>
{
  return {
    type: 'SELECT_PHOTOGRAPHER_LIST',
    payload: {
      list: photographerState
    }
  }
}


export const CHANGE_REISSUE_MAILADDRESS = 'CHANGE_REISSUE_MAILADDRESS'
export const changeReissueMailAddressAction = (photographerState) =>
{
  return {
    type: 'CHANGE_REISSUE_MAILADDRESS',
    payload: {
      reissueMailAddress: photographerState,
    }
  }
}

export const LOGIN_PHOTOGRAPHER = 'LOGIN_PHOTOGRAPHER'
export const loginPhotographerAction = (photographerState) =>
{
  return {
    type: 'LOGIN_PHOTOGRAPHER',
    payload: {
      loginStatus: true,
      photographer_id: photographerState.id,
      name: photographerState.name
    }
  }
}

export const LOGOUT_PHOTOGRAPHER = 'LOGOUT_PHOTOGRAPHER'
export const logoutPhotographerAction = (photographerState) =>
{
  return {
    type: 'LOGOUT_PHOTOGRAPHER',
    payload: {
      loginStatus: false,
      photographer_id: '',
      name: ''
    }
  }
}

export const CHANGE_APPLICATION_MAIL_ADDRESS = 'CHANGE_APPLICATION_MAIL_ADDRESS'
export const changeApplicationMailAddressAction = (photographerState) =>
{
  return {
    type: 'CHANGE_APPLICATION_MAIL_ADDRESS',
    payload: {
      applicationMailAddress: photographerState
    }
  }
}