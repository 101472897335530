import * as Actions from './actions'
import initialState from '../store/initialState'

export const PhotographersReducer = (state = initialState.photographers, action) =>
{
  switch(action.type)
  {
    case Actions.SELECT_PHOTOGRAPHER_LIST:
      return {
        ...state,
        ...action.payload
      }
    case Actions.CHANGE_APPLICATION_MAIL_ADDRESS:
      return {
        ...state,
        ...action.payload
      }
    case Actions.LOGIN_PHOTOGRAPHER:
      return {
        ...state,
        ...action.payload
      }
    case Actions.LOGOUT_PHOTOGRAPHER:
      return {
        ...state,
        ...action.payload
      }
    case Actions.CHANGE_REISSUE_MAILADDRESS:
      return {
        ...state,
        ...action.payload
      }
      
      default:
        return state

  }
}