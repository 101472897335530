import React, { useEffect, useCallback, useState } from "react"
import { selectOrderHistoryList } from '../../reducks/orders/operations'
import DocumentMeta from 'react-document-meta'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import { ImagesDir, RouteDir, ItemImageDir } from "../../common"
import { BreadCrumb } from '../../components'
import { Paging } from '../../components/UIkit'
import { SiteTitle } from './common'
import queryString from 'query-string';

const OrderHistory = (props) =>
{

  const dispatch = useDispatch()

  //ページング用 パラメータの取得
  const queryParam = queryString.parse(props.location.search)
  const [paged, setPaged] = useState('')
  const [showCount, setShowCount] = useState('')

  //購入履歴リスト
  const orderHistories = useSelector(state => state.orders.history)

  useEffect(()=>{

    //購入履歴の取得
    dispatch(selectOrderHistoryList())

    if(queryParam.paged)
    {
      setPaged(queryParam.paged)
    }
    else
    {
      setPaged(1)
    }
    setShowCount(10) //１ページに表示する数

  },[dispatch, queryParam.paged])



  const meta =
  {
    title: SiteTitle,
  }

  const Floors =
  [
    {
      name: '顧客一覧',
      href: '/mypage/customer_list'
    }
  ]

  return(
    <DocumentMeta {...meta}>
      <div id="customer_history_page">
        <BreadCrumb
          floors = { Floors }
        />
        <main className="member_edit_content">
          <h1>顧客一覧</h1>
          <section className="customer_search">
            <p className="customer_name">カメラマン名</p>
            <table>
              <tbody>
                <tr>
                  <td>顧客検索</td>
                </tr>
                <tr>
                  <td>
                    <p>顧客名（フリガナ）、撮影日で検索できます。</p>
                    <div className="search_btn">
                      <input
                        type = "text"
                        name = "search_name"
                      />
                      <button>検索</button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          <section className="customer_main">
            <div className="sort_box">
              <div className="box_left">
                <select name = "sort_order">
                  <option value="">表示順</option>
                  <option value="family_name_furigana ASC">全て</option>
                  <option value="family_name_furigana ASC">フリガナ</option>
                  <option value="insert_datetime DESC">登録日が新しい順</option>
                  <option value="insert_datetime ASC">登録日が古い順</option>
                </select>
                <div>
                  <button>変更</button>
                </div>
              </div>
              <div className="right_box">
                <div>
                  <button>新規登録</button>
                </div>
              </div>
            </div>
            <div className="customer_history_main">
              <table>
                <tbody>
                  <tr className="first_tr">
                    <td>撮影日</td>
                    <td>氏名</td>
                    <td>登録日<br/>更新日</td>
                    <td>編集・削除</td>
                  </tr>
                  <tr>
                    <td>撮影日</td>
                    <td>氏名</td>
                    <td>登録日<br/>更新日</td>
                    <td>
                      <div className="btn">
                        <div className="edit"><button>編集</button></div>
                        <div className="delete"><button>削除</button></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
          <section className="under_menu_area">
            <div className="left_group">
            </div>
            <div className="right_group">
              <Paging
              publicPage = {true}
                length = {orderHistories.length}
                paged = {paged}
                showCount = {showCount}
                prevtext = {'前へ'}
                nexttext = {'次へ'}
                slug = {'mypage/order_history'}
              />
            </div>
          </section>
        </main>
      </div>
    </DocumentMeta>
  )
}

export default OrderHistory