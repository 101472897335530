import React, { useEffect, useCallback, useState } from "react"
import { insertMemberPublic } from '../../reducks/members/operations'
import { changeLoading } from '../../reducks/pageInfos/operations'
import DocumentMeta from 'react-document-meta'
import { push } from 'connected-react-router'
import  {ImageSelectyButton, ImageList, UploadFile} from '../../components/UIkit'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { ApiDir, JsDir } from "../../common"
import { ImagesDir, RouteDir, ItemImageDir } from "../../common"
import { BreadCrumb } from '../../components'
import { SiteTitle } from './common'
import queryString from 'query-string';

const ItemList = (props) =>
{

  //ローディング
  const loading = useSelector(state => state.pageInfos.loading)

  const dispatch = useDispatch()
  const [photographer, setPhotographerId] = useState([])

  //姓の入力
  const [familyNameValue, setFamilyNameValue] = useState('')
  const inputFamilyNameValue = useCallback((event) =>
  {
    inputValueCheck()
    setFamilyNameValue(event.target.value)
  }, [setFamilyNameValue])

  //名の入力
  const [firstNameValue, setFirstNameValue] = useState('')
  const inputFirstNameValue = useCallback((event) =>
  {
    inputValueCheck()
    setFirstNameValue(event.target.value)
  }, [setFirstNameValue])

  //セイの入力
  const [familyNameFuriganaValue, setFamilyNameFuriganaValue] = useState('')
  const inputFamilyNameFuriganaValue = useCallback((event) =>
  {
    inputValueCheck()
    setFamilyNameFuriganaValue(event.target.value)
  }, [setFamilyNameFuriganaValue])

  //メイの入力
  const [firstNameFuriganaValue, setFirstNameFuriganaValue] = useState('')
  const inputFirstNameFuriganaValue = useCallback((event) =>
  {
    inputValueCheck()
    setFirstNameFuriganaValue(event.target.value)
  }, [setFirstNameFuriganaValue])

  //撮影日の入力
  const [ShootingDateValue, setShootingDateValue] = useState('')
  const inputShootingDateValue = useCallback((event) =>
  {
    inputValueCheck()
    setShootingDateValue(event.target.value)
  }, [setShootingDateValue])

  //生年月日の入力
  const [birthdayValue, setBirthdayValue] = useState('')
  const inputBirthdayValue = useCallback((event) =>
  {
    setBirthdayValue(event.target.value)
  }, [setBirthdayValue])

  //郵便番号の入力
  const [postalCodeValue, setPostalCodeValue] = useState('')
  const inputPostalCodeValue = useCallback((event) =>
  {
    setPostalCodeValue(event.target.value)
  }, [setPostalCodeValue])

  //住所の入力
  const [addressValue, setAddressValue] = useState('')
  const inputAddressValue = useCallback((event) =>
  {
    setAddressValue(event.target.value)
  }, [setAddressValue])

  //電話番号の入力
  const [telnumberValue, setTelnumberValue] = useState('')
  const inputTelnumberValue = useCallback((event) =>
  {
    setTelnumberValue(event.target.value)
  }, [setTelnumberValue])

  //メールアドレスの入力
  const [mailAddressValue, setMailAddressValue] = useState('')
  const inputMailAddressValue = useCallback((event) =>
  {
    setMailAddressValue(event.target.value)
  }, [setMailAddressValue])

  //メルマガの受信の入力
  const [mailMagazineFlagValue, setMailMagazineFlagValue] = useState('1')
  const inputMailMagazineFlagValue = useCallback((event) =>
  {
    setMailMagazineFlagValue(event.target.value)
  }, [setMailMagazineFlagValue])

  //カメラマン名の入力
  const [PhotographerIdValue, setPhotographerIdValue] = useState('')
  const inputPhotographerIdValue = useCallback((event) =>
  {
    setPhotographerIdValue(event.target.value)
  }, [setPhotographerIdValue])

  //URLの入力
  const [MembersUrlValue, setMembersUrlValue] = useState('')
  const inputMembersUrlValue = useCallback((event) =>
  {
    setMembersUrlValue(event.target.value)
  }, [setMembersUrlValue])

  //ステータスの入力
  const [StatusValue, setStatusValue] = useState('1')
  const inputStatusValue = useCallback((event) =>
  {
    setStatusValue(event.target.value)
  }, [setStatusValue])

  //入力値が入っているかの確認
  const inputValueCheck = () =>
  {
    // const inputValueOfFamilyName = document.getElementsByName('family_name')
    // const inputValueOfFirstName = document.getElementsByName('first_name')
    // const inputValueOfFamilyNameFurigana = document.getElementsByName('family_name_furigana')
    // const inputValueOfdFirstNameFurigana = document.getElementsByName('first_name_furigana')
    // const inputValueShootingDate = document.getElementsByName('shooting_date')

    // if(inputValueOfFamilyName[0].value !== '' && inputValueOfFirstName[0].value !== '' && inputValueOfFamilyNameFurigana[0].value !== '' && inputValueOfdFirstNameFurigana[0].value !== '' && inputValueShootingDate[0].value !== '')
    // {
    //   document.getElementById('insert_btn').classList.remove('desabled')
    // }
    // else
    // {
    //   document.getElementById('insert_btn').classList.add('desabled')
    // }
    const formElem = document.getElementById('insert_form');
    for (let i = 0; i < formElem.elements.length; i++) {
      const elem = formElem.elements[i];
      if (elem.tagName === 'INPUT') {
        /* inputタグのみチェック */
        if (elem.hasAttribute('required')) {
          if (elem.value.trim() == '') {
            document.getElementById('insert_btn').classList.add('desabled');
            return;
          }
        }
      }
    }
    document.getElementById('insert_btn').classList.remove('desabled');
  }

  //登録ボタン押下時の処理
  const sendFormData = () =>
  {
    
    //ローディング開始
    dispatch(changeLoading(true))

    //form情報の取得
    const formElement = document.getElementById('insert_form')
    const formData = new FormData(formElement);

    //formkeyの追加
    formData.append('formkey','insertkey')
    dispatch(insertMemberPublic(formData))
  }

  useEffect(()=>
  {
    let params = new URLSearchParams();
    //カメラマン名取得
    params = new URLSearchParams();
    params.append('formkey','selectkey');
    axios.post(ApiDir+'/selectPhotographerList.php',params)
    .then(function(response){
      console.log(response.data)
      setPhotographerId(response.data)
    })
    .catch(function(error){
      console.log(error)
      return
    })

  },[])


  const meta =
  {
    title: SiteTitle,
  }

  const Floors =
  [
    {
      name: '顧客新規追加',
      href: '/mypage/member_add'
    }
  ]

  return(
    <DocumentMeta {...meta}>
      <div id="customer_add_page">
        <BreadCrumb
          floors = { Floors }
        />
        <main className="member_edit_content form_type_1">
          <h1>顧客新規追加</h1>
          <section>
          <form encType="multipart/form-data" method="post" id="insert_form" onSubmit={(e)=>e.preventDefault()}>
            <table>
              <thead></thead>
              <tbody>
                <tr>
                  <th>氏名<span className="required">必須</span></th>
                  <td>
                    <div className="family_name_area">
                      <span>姓</span>
                      <input
                        type = "text"
                        name = {'family_name'}
                        className = ""
                        value = {familyNameValue}
                        onChange = {inputFamilyNameValue}
                      />
                    </div>
                    <div className="first_name_area">
                      <span>名</span>
                      <input
                        type = "text"
                        name = {'first_name'}
                        value = {firstNameValue}
                        onChange = {inputFirstNameValue}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>フリガナ<span className="required">必須</span></th>
                  <td>
                    <div className="family_name_area">
                      <span>セイ</span>
                      <input
                        type = "text"
                        name = {'family_name_furigana'}
                        value = {familyNameFuriganaValue}
                        onChange = {inputFamilyNameFuriganaValue}
                      />
                    </div>
                    <div className="first_name_area">
                      <span>メイ</span>
                      <input
                        type = "text"
                        name = {'first_name_furigana'}
                        value = {firstNameFuriganaValue}
                        onChange = {inputFirstNameFuriganaValue}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>撮影日<span className="required">必須</span></th>
                  <td>
                    <div className="family_name_area">
                      <span></span>
                    <input
                        type = "text"
                        name = {'shooting_date'}
                        value = {ShootingDateValue}
                        onChange = {inputShootingDateValue}
                        placeholder = "0000-00-00"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="button_area upload_area">
              <p>※<span className="red">Zip形式</span>のファイルをアップロードしてください。</p>
              {/* <ImageSelectyButton/> */}
              <UploadFile/>
            </div>
          </form>
          </section>
          <section className="under_menu_area">
            <div className="left_group">
            </div>
          </section>
          <div className="button_area">
          <button id = "insert_btn" className="desabled" onClick={()=>sendFormData()}>登 録</button>
          </div>
          <div id="loading_area" className={loading===true?'':'hidden'}>
            <div className="loader">Loading...</div>
          </div>
        </main>
      </div>
    </DocumentMeta>
  )
}

export default ItemList