import { useEffect } from 'react';
import { Route, Redirect, useLocation } from "react-router";
import { LoginCheckPhotographer } from './reducks/photographers/operations'
import { useDispatch, useSelector } from 'react-redux'
import { RouteDir } from './common';


const MemberAuth = (props) =>
{
  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(()=>{
    //ログインチェック
    dispatch(LoginCheckPhotographer())
  },[dispatch])

  const memberId = sessionStorage.getItem('photographer_id')
  // window.alert(memberId)
  if(memberId !== '' && memberId !== null && memberId !== 'undefined'){
    // window.alert('ログインしました。')
    return props.children
  }
  else
  {
    // window.alert('ログインをしてください。')
    return (
      <Route path={`${RouteDir}/mypage/*`} render={() => <Redirect to={{pathname:`${RouteDir}/login`,state:{from:location.pathname}}} />} />
    )
  }

}

export default MemberAuth;