import React, { useCallback, useEffect, useState } from "react";
import { insertMember } from '../../reducks/members/operations';
import { changeLoading } from '../../reducks/pageInfos/operations';
import  {ImageSelectyButton, ImageList, UploadFile} from '../../components/UIkit';
import { useDispatch,useSelector } from 'react-redux';
import axios from 'axios';
import { ApiDir, JsDir } from "../../common";
import { isMailAddressValid, isPasswordValid } from '../../myLib';
import importScript from '../../components/hooks/ImportScript';

const name =
{
  name: "image_",
}

const ItemList = (props) =>
{
  // svg操作のjsの読み込み
  importScript(JsDir + '/ajaxzip3.js')

  //ローディング
  const loading = useSelector(state => state.pageInfos.loading)

  const dispatch = useDispatch()
  const [photographer, setPhotographerId] = useState([])

  //姓の入力
  const [familyNameValue, setFamilyNameValue] = useState('')
  const inputFamilyNameValue = useCallback((event) =>
  {
    inputValueCheck()
    setFamilyNameValue(event.target.value)
  }, [setFamilyNameValue])

  //名の入力
  const [firstNameValue, setFirstNameValue] = useState('')
  const inputFirstNameValue = useCallback((event) =>
  {
    inputValueCheck()
    setFirstNameValue(event.target.value)
  }, [setFirstNameValue])

  //セイの入力
  const [familyNameFuriganaValue, setFamilyNameFuriganaValue] = useState('')
  const inputFamilyNameFuriganaValue = useCallback((event) =>
  {
    inputValueCheck()
    setFamilyNameFuriganaValue(event.target.value)
  }, [setFamilyNameFuriganaValue])

  //メイの入力
  const [firstNameFuriganaValue, setFirstNameFuriganaValue] = useState('')
  const inputFirstNameFuriganaValue = useCallback((event) =>
  {
    inputValueCheck()
    setFirstNameFuriganaValue(event.target.value)
  }, [setFirstNameFuriganaValue])

  //撮影日の入力
  const [ShootingDateValue, setShootingDateValue] = useState('')
  const inputShootingDateValue = useCallback((event) =>
  {
    inputValueCheck()
    setShootingDateValue(event.target.value)
  }, [setShootingDateValue])

  //生年月日の入力
  const [birthdayValue, setBirthdayValue] = useState('')
  const inputBirthdayValue = useCallback((event) =>
  {
    setBirthdayValue(event.target.value)
  }, [setBirthdayValue])

  //郵便番号の入力
  const [postalCodeValue, setPostalCodeValue] = useState('')
  const inputPostalCodeValue = useCallback((event) =>
  {
    setPostalCodeValue(event.target.value)
  }, [setPostalCodeValue])

  //住所の入力
  const [addressValue, setAddressValue] = useState('')
  const inputAddressValue = useCallback((event) =>
  {
    setAddressValue(event.target.value)
  }, [setAddressValue])

  //電話番号の入力
  const [telnumberValue, setTelnumberValue] = useState('')
  const inputTelnumberValue = useCallback((event) =>
  {
    setTelnumberValue(event.target.value)
  }, [setTelnumberValue])

  //メールアドレスの入力
  const [mailAddressValue, setMailAddressValue] = useState('')
  const inputMailAddressValue = useCallback((event) =>
  {
    setMailAddressValue(event.target.value)
  }, [setMailAddressValue])

  //メルマガの受信の入力
  const [mailMagazineFlagValue, setMailMagazineFlagValue] = useState('1')
  const inputMailMagazineFlagValue = useCallback((event) =>
  {
    setMailMagazineFlagValue(event.target.value)
  }, [setMailMagazineFlagValue])

  //カメラマン名の入力
  const [PhotographerIdValue, setPhotographerIdValue] = useState('')
  const inputPhotographerIdValue = useCallback((event) =>
  {
    setPhotographerIdValue(event.target.value)
  }, [setPhotographerIdValue])

  //URLの入力
  const [MembersUrlValue, setMembersUrlValue] = useState('')
  const inputMembersUrlValue = useCallback((event) =>
  {
    setMembersUrlValue(event.target.value)
  }, [setMembersUrlValue])

  //ステータスの入力
  const [StatusValue, setStatusValue] = useState('1')
  const inputStatusValue = useCallback((event) =>
  {
    setStatusValue(event.target.value)
  }, [setStatusValue])

  // 入力チェック (update)20230714
  const inputValueCheck = () =>{
    // const inputValueOfFamilyName = document.getElementsByName('family_name');
    // const inputValueOfFirstName = document.getElementsByName('first_name');
    // const inputValueOfFamilyNameFurigana = document.getElementsByName('family_name_furigana');
    // const inputValueOfdFirstNameFurigana = document.getElementsByName('first_name_furigana');
    // const inputValueOfShootingDate = document.getElementsByName('shooting_date');

    // if(inputValueOfFamilyName[0].value !== '' && inputValueOfFirstName[0].value !== '' && inputValueOfFamilyNameFurigana[0].value !== '' && inputValueOfdFirstNameFurigana[0].value !== '' && inputValueOfShootingDate[0].value !== ''){
    //   document.getElementById('insert_btn').classList.remove('desabled');
    // }else{
    //   document.getElementById('insert_btn').classList.add('desabled')
    // }
    const formElem = document.getElementById('add_form');
    for (let i = 0; i < formElem.elements.length; i++) {
      const elem = formElem.elements[i];
      if (elem.tagName === 'INPUT') {
        /* inputタグのみチェック */
        if (elem.hasAttribute('required')) {
          if (elem.value.trim() == '') {
            document.getElementById('insert_btn').classList.add('desabled');
            return;
          }
        }
      }
    }
    document.getElementById('insert_btn').classList.remove('desabled');
  };
  
  //登録ボタン押下時の処理
  const sendFormData = () =>
  {
    
    //ローディング開始
    dispatch(changeLoading(true))

    //form情報の取得
    const formElement = document.getElementById('add_form')
    const formData = new FormData(formElement);

    //formkeyの追加
    formData.append('formkey','insertkey')
    console.log(formData);
    dispatch(insertMember(formData))
  }

  //住所自動入力
  const {AjaxZip3} = window
  const postalAutoInput = ()=>
  {
    AjaxZip3.zip2addr('postal_code','','address','address')
    setTimeout(()=>{
      const addressElement = document.getElementsByName('address')
      const addressValue = addressElement[0].value
      setAddressValue(addressValue)
    },100)
  }

  useEffect(()=>
  {
    let params = new URLSearchParams();
    //カメラマン名取得
    params = new URLSearchParams();
    params.append('formkey','selectkey');
    axios.post(ApiDir+'/selectPhotographerList.php',params)
    .then(function(response){
      console.log(response.data)
      setPhotographerId(response.data)
    })
    .catch(function(error){
      console.log(error)
      return
    })

  },[])

  // const images = [
  //   {id:1, name:'１'},
  //   {id:2, name:'２'},
  //   {id:3, name:'３'},
  //   {id:4, name:'４'}
  // ]

  const [images, setImages] = useState({data: []});
  // const [file, setFile] = useState([]);
  const [up, setUp] = useState({data: []});

  return(
    <main id="admin_add_page">
      <h2 className="page_title"><span>顧客新規登録</span></h2>
      <section className="box_type_1">
        <div className="title_area">
          <h3>顧客情報</h3>
        </div>
        <div className="content_area">
          <form encType="multipart/form-data" method="post" id="add_form" onSubmit={(e)=>e.preventDefault()}>
            <table>
              <thead></thead>
              <tbody>
                <tr>
                  <th>氏名<span className="required">必須</span></th>
                  <td className="flex">
                    <span className="header">姓</span>
                    <input
                      type = "text"
                      name = {'family_name'}
                      className = ""
                      value = {familyNameValue}
                      onChange = {inputFamilyNameValue}
                      required
                    />
                    <br/>
                    <span className="header">名</span>
                    <input
                      type = "text"
                      name = {'first_name'}
                      value = {firstNameValue}
                      onChange = {inputFirstNameValue}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <th>フリガナ<span className="required">必須</span></th>
                  <td className="flex">
                  <span className="header">セイ</span>
                    <input
                      type = "text"
                      name = {'family_name_furigana'}
                      value = {familyNameFuriganaValue}
                      onChange = {inputFamilyNameFuriganaValue}
                      required
                    />
                    <br/>
                    <span className="header">メイ</span>
                    <input
                      type = "text"
                      name = {'first_name_furigana'}
                      value = {firstNameFuriganaValue}
                      onChange = {inputFirstNameFuriganaValue}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <th>撮影日<span className="required">必須</span></th>
                  <td>
                    <input
                      type = "text"
                      name = {'shooting_date'}
                      value = {ShootingDateValue}
                      onChange = {inputShootingDateValue}
                      placeholder = "0000-00-00"
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <th>カメラマン名</th>
                  <td className="birthday_box">
                  <select
                      name = {'photographer_id'}
                      value = {PhotographerIdValue}
                      onChange = {inputPhotographerIdValue}
                    >
                      {Array.isArray(photographer) && photographer.map((option) => (
                      <option key={option.id} value={option.id}>{option.name}</option>
                    ))}
                    </select>
                  </td>
                </tr>
                <tr className="img_upload">
                  <th>画像ファイル<br></br><span class="small red">※Zip形式のみ</span></th>
                  <td>
                    {/* <ImageSelectyButton/> */}
                    <UploadFile/>
                    {/* <form id="form" enctype="multipart/form-data">
                      <input type="file" name="file" id="file-input" required></input>
                      <button type="submit">アップロード</button>
                    </form>
                    <p>ファイルサイズ: <span id="input-size"></span></p>
                    <p>アップロード結合サイズ: <span id="upload-size"></span></p>
                    <p>進捗: <span id="progress"></span>%</p>
                    <script src="/upload-test/js/upload.js"></script> */}
                  </td>
                </tr>
                {/* {Array.isArray(images) && images.map((image, index) => (
                  <tr key={index}>
                    <th>商品画像{image.name}</th>
                    <td className="progress_key_element">
                    <ImageSelectyButton
                      id={'image_'+image.id}
                      className={'file_btn'}
                      imageSrc={''}
                      setSelectImageDeleteFlag = {'add'}
                    />
                    </td>
                  </tr>
                ))} */}
                <tr>
                  <th>状態</th>
                  <td className="mail_magazine_flag_box">
                  <label className="radio_btn_label">
                    <input
                      type="radio"
                      name="status"
                      value={1}
                      checked = {StatusValue === '1'}
                      onChange = {inputStatusValue}
                    />下書き
                  </label>
                  <br/>
                  <label className="radio_btn_label">
                    <input
                      type="radio"
                      name="status"
                      value={0}
                      checked = {StatusValue === '0'}
                      onChange = {inputStatusValue}
                    />公開
                  </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
        <div className="button_area">
          <button id = "insert_btn" className="desabled" onClick={()=>sendFormData()}>登録</button>
        </div>
      </section>
      <div id="loading_area" className={loading===true?'':'hidden'}>
        <div className="loader">Loading...</div>
      </div>
    </main>
  );

}

export default ItemList