import React from "react"
import DocumentMeta from 'react-document-meta'
import { BreadCrumb } from '../../components'
import { SiteTitle } from './common'

const Howto = () =>
{
  const meta =
  {
    title: SiteTitle,
  }

  const Floors =
  [
    {
      name: 'ご利用について',
      href: '/howto'
    }
  ]

  return(
    <DocumentMeta {...meta}>
      <div id="howto_page">
        <BreadCrumb
          floors = { Floors }
        />
        <main className="howto_content">
          <h1>ご利用について</h1>
          <section id="notes">
            <h2>注意事項</h2>
            <div className="text_area">
              <p>
                ※ 当サービスにて画像をダウンロードされるにあたり、様々な環境が必要となります。<br></br>場合によっては、ダウンロードに時間がかかったり、ご利用できない場合もありますので、必ず以下の動作環境をご確認の上、ご利用ください。
              </p>
              <p>
                ※ 「一括ダウンロード」は、ZIP形式に圧縮されてダウンロードされます。<br></br>1枚あたりの画像サイズも大きいですので、「一括ダウンロード」をされる場合は、通信環境の良い環境でのご利用、パソコンでのダウンロードを推奨します。<br></br>スマーフォンで「一括ダウンロード」をされる場合は、ZIP形式のファイルを解凍できるアプリケーションをご準備ください。
              </p>
            </div>
          </section>
          <section id="environment">
            <h2>動作推奨環境</h2>
            <div className="text_area">
              <p>
                容量の大きい画像や、大量のデータを扱います。動作環境をご確認の上ご利用ください。
              </p>
              <ol>
                <li>
                  パソコン環境（OS・ブラウザ）のご確認
                  <ul className="list_disc">
                    <li>OS： Windows 10、MacOS最新版</li>
                    <li>対応ブラウザ：Google Chrome最新版、Safari最新版</li>
                  </ul>
                </li>
                <li>
                  スマーフォン環境（OS・ブラウザ）のご確認
                  <ul className="list_disc">
                    <li>OS：Android最新版、iOS最新版</li>
                    <li>対応ブラウザ：Google Chrome最新版、Safari最新版</li>
                  </ul>
                </li>
                <li>
                  インターネット環境のご確認
                  <ul className="list_disc">
                    <li>大量のデータを扱います。通信速度が速ければより快適に画像をダウンロードすることが出できます。</li>
                    <li>WiFi環境など安定した通信環境を推奨します。</li>
                  </ul>
                </li>
              </ol>
            </div>
            
          </section>
          
        </main>
      </div>
    </DocumentMeta>
  )
}

export default Howto