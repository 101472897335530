import React, { useEffect, useCallback, useState } from "react"
import { selectOrderHistoryList } from '../../reducks/orders/operations'
import DocumentMeta from 'react-document-meta'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import { ImagesDir, RouteDir, ItemImageDir } from "../../common"
import { BreadCrumb } from '../../components'
import { Paging } from '../../components/UIkit'
import { SiteTitle } from './common'
import queryString from 'query-string';

const OrderHistory = (props) =>
{

  const dispatch = useDispatch()

  //ページング用 パラメータの取得
  const queryParam = queryString.parse(props.location.search)
  const [paged, setPaged] = useState('')
  const [showCount, setShowCount] = useState('')

  //購入履歴リスト
  const orderHistories = useSelector(state => state.orders.history)

  useEffect(()=>{

    //購入履歴の取得
    dispatch(selectOrderHistoryList())

    if(queryParam.paged)
    {
      setPaged(queryParam.paged)
    }
    else
    {
      setPaged(1)
    }
    setShowCount(10) //１ページに表示する数

  },[dispatch, queryParam.paged])



  const meta =
  {
    title: SiteTitle,
  }

  const Floors =
  [
    {
      name: '顧客一覧',
      href: '/mypage/customer_history'
    }
  ]

  return(
    <DocumentMeta {...meta}>
      <div id="order_history_page">
        <BreadCrumb
          floors = { Floors }
        />
        <main className="member_edit_content">
          <h1>顧客一覧</h1>
          <section className="under_menu_area">
            <div className="left_group">
            </div>
            <div className="right_group">
              <Paging
              publicPage = {true}
                length = {orderHistories.length}
                paged = {paged}
                showCount = {showCount}
                prevtext = {'前へ'}
                nexttext = {'次へ'}
                slug = {'mypage/order_history'}
              />
            </div>
          </section>
        </main>
      </div>
    </DocumentMeta>
  )
}

export default OrderHistory