import {
  selectPhotographerListAction,
  loginPhotographerAction,
  logoutPhotographerAction,
  changeReissueMailAddressAction,
} from '../photographers/actions'
import {
  changeLoadingAction,
} from '../pageInfos/actions'
import { push } from 'connected-react-router'
import axios from 'axios'
import { AdminsDir, RouteDir, ApiDir, MypageDir } from "../../common"

//カメラマン情報一覧取得
export const selectPhotographerList = (props) =>
{
  return async (dispatch) =>
  {
    let params = new URLSearchParams()
    params.append('limit',props.Limit)
    params.append('offset',props.Offset)
    params.append('sort',props.Sort)
    params.append('formkey','selectkey')

    axios.post(ApiDir+'/selectPhotographerList.php',params)
    .then(function(response){
      console.log(response.data)
      dispatch(selectPhotographerListAction(response.data))
    })
    .catch(function(error){
      console.log(error)
      return
    })
    .finally(function(){
      return
    })
  }
}

//カメラマン情報検索
export const searchPhotographerList = (props) =>
{
  return async (dispatch) =>
  {
    let params = new URLSearchParams()
    params.append('photographer_name',props.photographerName)
    params.append('formkey','selectkey')
    console.log(props.photographerName)

    axios.post(ApiDir+'/searchPhotographerList.php',params)
    .then(function(response){
      console.log(response.data)
      dispatch(selectPhotographerListAction(response.data))
      // console.log(dispatch)
      return
    })
    .catch(function(error){
      console.log(error)
      return
    })
    .finally(function(){
      return
    })
  }
}

//カメラマン情報一括操作
export const bulkOperationPhotographer = (selectValue, selectCheckboxValue) =>
{
  return async (dispatch) =>
  {
    let params = new URLSearchParams()
    params.append('select_value',selectValue)
    params.append('select_checkbox_value',JSON.stringify(selectCheckboxValue))
    params.append('formkey','bulk_operationkey')
    axios.post(ApiDir + '/bulkOperationAdmin.php',params)
    .then(function(response){
      if(response)
      {
        console.log(response.data)
        window.location.reload()
      }
      else
      {
        window.alert('カメラマン情報操作に失敗しました。');
      }
    })
    .catch(function(error){
      console.log(error)
      return
    })
  }
}

//カメラマン登録
export const insertPhotographer = (formData) =>
{
  return async (dispatch) =>
  {
    const password = formData.get('password')
    const re_password = formData.get('re_password')

    //バリデーション
    if(password !== re_password)
    {
      alert('パスワードが一致しません')
      return false
    }

    axios.post(ApiDir + '/insertPhotographer.php',formData)
    .then(function(response){
      window.alert('カメラマン情報を追加しました。')
      dispatch(push(AdminsDir+'/photographers/list'))
    })
    .catch(function(error){
      console.log(error)
      return
    })
    .finally(function(){
      return
    })
  }
}

//カメラマン情報更新
export const updatePhotographer = (formData) =>
{
  return async (dispatch) =>
  {
    axios.post(ApiDir + '/updatePhotographer.php',formData)
    .then(function(response){
      if(response)
      {
        alert('カメラマン情報を変更しました。')
        dispatch(push(AdminsDir+'/photographers/list'))
      }
      else
      {
        alert('カメラマン情報の変更に失敗しました。')
      }
    })
    .catch(function(error){
      console.log(error)
      return
    })
    .finally(function(){
      return
    })
  }
}

//カメラマン情報削除
export const deletePhotographer = (id) =>
{
  return async (dispatch) =>
  {
    let params = new URLSearchParams()
    params.append('photographer_id',id)
    params.append('formkey','deletekey')

    axios.post(ApiDir + '/deletePhotographer.php',params)
    .then(function(response){
      if(response)
      {
        window.alert('カメラマン情報を削除しました。');
        // dispatch(push(AdminsDir+'/photographers/list'))
        window.location.reload()
      }
      else
      {
        window.alert('カメラマン情報を削除に失敗しました。');
      }
    })
    .catch(function(error){
      console.log(error)
      return
    })
  }
}

// カメラマンログイン処理
export const loginPhotographer = (formData, props) =>
{
  return async (dispatch) =>
  {
    axios.post(ApiDir + '/loginPhotographer.php',formData)
    .then(function(response){
      console.log(response.data)
      if(response.data.length !== 0 && response.data !== 'error')
      {
        // console.log(MypageDir+'/member_list')
        dispatch(loginPhotographerAction(response.data['login_photographer'][0]))
        if(props.location.state!==undefined)
        {
          sessionStorage.setItem('photographer_id', response.data['login_photographer'][0]['id'])
          dispatch(push(props.location.state.from))
        }
        else{
          //セッションストレージにログイン情報を追加
          sessionStorage.setItem('photographer_id', response.data['login_photographer'][0]['id'])
          dispatch(push(MypageDir+'/member_list'))
          // props.history.goBack()  //前のページにもどる
        }
      }
      else
      {
        window.alert('ログインに失敗しました。メールアドレス、パスワードをお確かめください。')
      }
    })
    .catch(function(error){
      console.log(error)
      return
    })
    .finally(function(){
      return
    })
  }
}

//カメラマンログインチェック
export const LoginCheckPhotographer = () =>
{
  return async (dispatch) =>
  {
    let params = new URLSearchParams()
    params.append('formkey','checkkey')
    axios.post(ApiDir + '/loginCheckPhotographer.php', params)
    .then(function(response){
      if(response.data.length !== 0 && response.data !== 'nologin' && response.data !== 'error')
      {
    //セッションストレージにログイン情報を追加
        
        sessionStorage.setItem('photographer_id', response.data['login_photographer'][0]['id'])
        dispatch(loginPhotographerAction(response.data['login_photographer'][0]))
        console.log(response.data)
        // dispatch(selectFavoriteListAction(response.data['favorite']))
        // dispatch(selectCartListAction(response.data['cart']))
      }
      else
      {
        sessionStorage.removeItem('photographer_id')
        dispatch(logoutPhotographerAction())
        // dispatch(selectFavoriteListAction([]))
        // dispatch(selectCartListAction([]))
      }
    })
    .catch(function(error){
      console.log(error)
      return
    })
    .finally(function(){
      return
    })
  }
}

//カメラマンログアウト処理
export const logoutPhotographer = () =>
{
  return async (dispatch) =>
  {
    let params = new URLSearchParams()
    params.append('formkey','logoutkey')
    axios.post(ApiDir + '/logoutMember.php', params)
    .then(function(response){
      console.log(response.data)
      sessionStorage.removeItem('photographer_id')
      dispatch(logoutPhotographerAction())
      dispatch(push(RouteDir))
      // dispatch(selectFavoriteListAction([]))
      // dispatch(selectCartListAction([]))
      window.location.reload()
      // dispatch(push(RouteDir))
    })
    .catch(function(error){
      console.log(error)
      return
    })
    .finally(function(){
      return
    })
  }
}

//カメラマンパスワード再発行申請
export const insertReissueApplication = (FormData) =>
{
  return async (dispatch) =>
  {
    axios.post(ApiDir + '/insertReissueApplicationByPhotographer.php', FormData)
    .then(function(response){
      dispatch(changeLoadingAction(false))
      console.log(response.data)
      dispatch(push(RouteDir+'/reissue_application_done'))
    })
    .catch(function(error){
      console.log(error)
      return
    })
    .finally(function(){
      return
    })
  }
}

//カメラマンパスワード変更
export const updatePhotographerPassword = (FormData) =>
{
  return async (dispatch) =>
  {
    axios.post(ApiDir + '/updatePhotographerPassword.php', FormData)
    .then(function(response){
      console.log(response.data);
      dispatch(push(RouteDir+'/login'))
      // window.location.reload()
    })
    .catch(function(error){
      console.log(error)
      return
    })
    .finally(function(){
      return
    })
  }
}

//カメラマンパスワード変更時のシリアルチェック
export const reissueCheck = (serial) =>
{
  return async (dispatch) =>
  {
    let params = new URLSearchParams()
    params.append('serial',serial)
    params.append('formkey','checkkey')
    axios.post(ApiDir + '/checkReissue.php',params)
    .then(function(response){
      if(response.data==='overtime')
      {
        dispatch(push(RouteDir+'/reissue_overtime'))
      }
      else{
        dispatch(changeReissueMailAddressAction(response.data))
      }
    })
    .catch(function(error){
      console.log(error)
      return
    })
    .finally(function(){
      return
    })
  }
}
// //管理者ログイン処理
// export const loginAdmin = (formData, props) =>
// {
//   return async (dispatch) =>
//   {
//     axios.post(ApiDir + '/loginAdmin.php',formData)
//     .then(function(response){
//       if(response.data.length !== 0 && response.data !== 'error')
//       {
//         console.log(response.data)
//         dispatch(loginAdminAction(response.data[0]))
//         dispatch(push(AdminsDir+'/home'))
//       }
//       else
//       {
//         window.alert('ログインに失敗しました。メールアドレス、パスワードをお確かめください。')
//       }
//     })
//     .catch(function(error){
//       console.log(error)
//       return
//     })
//     .finally(function(){
//       return
//     })
//   }
// }

// //管理者ログインチェック
// export const LoginCheckAdmin = () =>
// {
//   return async (dispatch) =>
//   {
//     let params = new URLSearchParams()
//     params.append('formkey','checkkey')
//     axios.post(ApiDir + '/loginCheckAdmin.php', params)
//     .then(function(response){
//       console.log(response.data)
//       if(response.data.length !== 0 && response.data !== 'nologin' && response.data !== 'error')
//       {
//         dispatch(loginAdminAction(response.data[0]))
//       }
//       else
//       {
//         dispatch(logoutAdminAction())
//       }
//     })
//     .catch(function(error){
//       console.log(error)
//       return
//     })
//     .finally(function(){
//       return
//     })
//   }
// }

// //管理者ログアウト処理
// export const logoutAdmin = () =>
// {
//   return async (dispatch) =>
//   {
//     let params = new URLSearchParams()
//     params.append('formkey','logoutkey')
//     axios.post(ApiDir + '/logoutAdmin.php', params)
//     .then(function(response){
//       console.log(response.data)
//       dispatch(logoutAdminAction())
//       window.location.reload()
//     })
//     .catch(function(error){
//       console.log(error)
//       return
//     })
//     .finally(function(){
//       return
//     })
//   }
// }

// //管理者パスワード再発行申請
// export const insertReissueApplication = (FormData) =>
// {
//   return async (dispatch) =>
//   {
//     axios.post(ApiDir + '/insertReissueApplicationByAdmin.php', FormData)
//     .then(function(response){
//       console.log(response.data)
//       dispatch(changeLoadingAction(false))
//       dispatch(push(AdminsDir+'/login'))
//     })
//     .catch(function(error){
//       console.log(error)
//       return
//     })
//     .finally(function(){
//       return
//     })
//   }
// }

// //管理者パスワード変更時のシリアルチェック
// export const reissueCheck = (serial) =>
// {
//   return async (dispatch) =>
//   {
//     let params = new URLSearchParams()
//     params.append('serial',serial)
//     params.append('formkey','checkkey')
//     axios.post(ApiDir + '/checkReissue.php',params)
//     .then(function(response){
//       if(response.data==='overtime')
//       {
//         dispatch(push(AdminsDir+'/admin_reissue_overtime'))
//       }
//       else{
//         dispatch(changeReissueMailAddressAction(response.data))
//       }
//     })
//     .catch(function(error){
//       console.log(error)
//       return
//     })
//     .finally(function(){
//       return
//     })
//   }
// }

// //管理者パスワード変更
// export const updateAdminPassword = (FormData) =>
// {
//   return async (dispatch) =>
//   {
//     axios.post(ApiDir + '/updateAdminPassword.php', FormData)
//     .then(function(response){
//       window.alert('パスワードを変更しました。')
//       dispatch(push(AdminsDir+'/login'))
//     })
//     .catch(function(error){
//       console.log(error)
//       return
//     })
//     .finally(function(){
//       return
//     })
//   }
// }


