import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// import { PostActions } from "@/module/post";
// import styled from "../../../node_modules/styled-components/packages/styled-components";

const ImageSelectyButton = (props) => {
	const [isDrag, setIsDrag] = useState(false);
	const [images, setImages] = useState([]);

// 	useEffect(() => {
// 			dispatch(PostActions.updateImages(images),
// }, [images]);

	// ファイル選択＆ドラッグからの処理
	const handleChangeFile = files => {
    const merge = [];
    console.log(merge);
    (async () => {
        await Promise.all(
            Object.keys(files).map(async item => {
                const file = files[Number(item)];
                const reader = await readerOnloadend(file);
                merge.push({ file: file, previewUrl: reader.result });
            })
        );
        setImages([...images, ...merge]);
    })();
	};

	// await reader.onloadend
	const readerOnloadend = async (data) => {
			return new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.onloadend = () => {
							resolve(reader);
					};
					reader.readAsDataURL(data);
			});
	};

	// 削除
	const remove = (index) => {
    images.splice(index, 1);
    setImages([...images]);
	};

	// クリア
	const clear = () => {
			setImages([]);
	};

	// ドラッグ状態
	// const dragState = (e, state) => {
	// 		e.preventDefault();
	// 		setIsDrag(state);
	// };

	// プレビュー画像表示
	const previews = Array.from(images || []).map(
		(image, index) => {
			return (
				<div className="element" key={index}>
					<img src={image.previewUrl} alt={image.file.name} />
					{/* <img src={image.previewUrl} alt="画像ファイル" /> */}
					<button
						onClick={(e) => {
							remove(index);
						}}
					>
					</button>
				</div>
			);
		}
	);

	// EDIT時にすでに登録済みのstrageからの画像をfile(blob)に変換
	// const { records, isRecord } = useSelector((state) => state.posts);
	// useEffect(() => {
	// 		if (!isRecord || !records[0]) return;
	// 		dispatch(PostActions.isRecord(false));
	// 		(async () => {
	// 				const recordImages= [];
	// 				await Promise.all(
	// 						records.map(async item => {
	// 								const response = await fetch(`/storage/${item.path}`);
	// 								const arrayBuffer = await response.arrayBuffer();
	// 								const blob = new Blob([arrayBuffer], {type: "image/jpeg"});
	// 								const reader = await readerOnloadend(blob);
	// 								recordImages.push({
	// 										file: new File([blob], "name"),
	// 										previewUrl: reader.result
	// 								});
	// 						})
	// 				);
	// 				setImages([...recordImages]);
	// 		})();
	// }, [isRecord]);
	// ---------------------------
	return (
		<section className="upload_box">
			<div className="upload_img">
				<p>ここにファイルをドロップ<br></br>またはクリックして選択</p>
					<input
						id="file1"
						type="file"
						name="image[]"
						accept="image/*"
						multiple
						onChange={e => handleChangeFile(e.target.files)}
					/>
			</div>
			<div className="preview">{previews}</div>
		</section>
	);
};

export default ImageSelectyButton;