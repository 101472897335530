import React, { useCallback, useEffect, useState } from 'react'
import { adminLogout } from '../../reducks/admins/operations'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import { RouteDir } from '../../common'

const Header = () =>
{
  const dispatch = useDispatch()

  const telnumberStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/icon_tel.svg')`
  }

  const mailformStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/icon_mail.svg')`
  }

  const footerStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/footer_bg.svg')`
  }

  return(
    <>
    <footer>
      {/* <div className="subline_1000"> */}
      <div className="contact_infomation_area" style={footerStyle}>
        <div className="telnumber_area">
          <p>お電話によるお問い合わせ</p>
          <p className="telnumber" style={telnumberStyle}>0957-48-5822</p>
          <p>10:00～18:00</p>
        </div>
        <div className="logo_image_area">
          <img src={process.env.PUBLIC_URL + '/images/header_logo.svg'}  alt="ブライダルポケット" />
        </div>
        <div className="mailform_area">
          <p>メールによるお問い合わせ</p>
          <div className="mailform" style={mailformStyle}>
            <button className="btn_type_2" onClick={()=>dispatch(push(RouteDir+'/contact'))}>お問い合わせ</button>
          </div>
        </div>
      </div>
      <div className="subline_1000">
        <aside className="footer_menu">
          <ul>
            <li onClick={()=>dispatch(push(RouteDir))}>トップページ</li>
            {/* <li onClick={()=>dispatch(push(RouteDir+'/howto'))}>ご利用について</li>
            <li onClick={()=>dispatch(push(RouteDir+'/commercial_transaction'))}>特定商取引法に基づく表記</li> */}
            <li onClick={()=>dispatch(push(RouteDir+'/privacy_policy'))}>プライバシーポリシー</li>
          </ul>
          <div className="media_area">
            <span>公式メディア</span>
            <a href="https://www.instagram.com/bridalpocket/" target="_blank">
              <img src={process.env.PUBLIC_URL + '/images/icon_instagram.svg'} alt="instagram" />
            </a>
            <a href="https://twitter.com/bridalpocket?ref_src=twsrc%5Etfw%7Ctwcamp%5Eembeddedtimeline%7Ctwterm%5Eprofile%3Abridalpocket%7Ctwgr%5EeyJ0ZndfZXhwZXJpbWVudHNfY29va2llX2V4cGlyYXRpb24iOnsiYnVja2V0IjoxMjA5NjAwLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X3NlbnNpdGl2ZV9tZWRpYV9pbnRlcnN0aXRpYWxfMTM5NjMiOnsiYnVja2V0IjoiaW50ZXJzdGl0aWFsIiwidmVyc2lvbiI6bnVsbH0sInRmd190d2VldF9yZXN1bHRfbWlncmF0aW9uXzEzOTc5Ijp7ImJ1Y2tldCI6InR3ZWV0X3Jlc3VsdCIsInZlcnNpb24iOm51bGx9fQ%3D%3D&ref_url=https%3A%2F%2Fwww.bridalpocket.com%2F" target="_blank">
              <img src={process.env.PUBLIC_URL + '/images/icon_twitter.svg'} alt="Twitter" />
            </a>
          </div>
        </aside>
        
        {/* <div className="logo_image_area">
          <img src={process.env.PUBLIC_URL + '/images/footer_logo.svg'}  alt="ブライダルポケット" />
        </div> */}
      </div>
      <div className="copylight_area">
        <p>© Bridal Pocket</p>
      </div>
    </footer>
    </>
  )
}

export default Header