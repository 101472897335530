import React, { useEffect, useCallback, useState } from "react"
import { selectMember, searchMemberPublicList, deleteMember, bulkOperationMember } from '../../reducks/members/operations'
import DocumentMeta from 'react-document-meta'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import { MypageDir, ImagesDir, RouteDir, ItemImageDir } from "../../common"
import { BreadCrumb } from '../../components'
import { Paging } from '../../components/UIkit'
import { SiteTitle } from './common'
import queryString from 'query-string';

const OrderHistory = (props) =>
{

  const dispatch = useDispatch()
  const members = useSelector(state => state.members.list)

  //検索商品名の入力
  const [memberName, setMemberName] = useState('')
  const inputMemberName = useCallback((event) =>
  {
    setMemberName(event.target.value)
  }, [setMemberName])

  //検索撮影日の入力
  const [shootingDate, setShootingDate] = useState('')
  const inputShootingDate = useCallback((event) =>
  {
    setShootingDate(event.target.value)
  }, [setShootingDate])

  //表示順の入力
  const [sortOrder, setSortOrder] = useState('id DESC')
  const inputSortOrder = useCallback((event) =>
  {
    setSortOrder(event.target.value)
  }, [setSortOrder])

  //一括操作の入力
  const [bulkOperation, setBulkOperation] = useState('')
  const inputBulkOperation = useCallback((event) =>
  {
    setBulkOperation(event.target.value)
  }, [setBulkOperation])

  //ページング用 パラメータの取得
  const queryParam = queryString.parse(props.location.search)
  const [paged, setPaged] = useState('')
  const [showCount, setShowCount] = useState('')

  //購入履歴リスト
  const orderHistories = useSelector(state => state.orders.history)

  useEffect(()=>{

    //管理者リストの取得、セット
    const selectMemberprops = {
      Limit: -1,
      Offset: 0,
      Sort: sortOrder
    }
    dispatch(selectMember(selectMemberprops))
    if(queryParam.paged)
    {
      setPaged(queryParam.paged)
    }
    else
    {
      setPaged(1)
    }
    setShowCount(50) //１ページに表示する数

  },[dispatch, queryParam.paged])

  const InsertButtonStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/insert_icon.png')`
  }

  const sortMemberList = () =>
  {
    //商品リストの取得、セット
    const selectMemberprops = {
      Limit: -1,
      Offset: 0,
      Sort: sortOrder
    }
    dispatch(selectMember(selectMemberprops))
    if(queryParam.paged)
    {
      setPaged(queryParam.paged)
    }
    else
    {
      setPaged(1)
    }
    return
  }

  const searchMemberListBtn = () =>
  {
    //商品リストの取得、セット
    const searchMemberprops = {
      MemberName: memberName
    }
    dispatch(searchMemberPublicList(searchMemberprops))
    if(queryParam.paged)
    {
      setPaged(queryParam.paged)
    }
    else
    {
      setPaged(1)
    }
  }

  const deleteConfirm = (memberId) =>
  {
    const result = window.confirm('会員情報を削除してよろしいですか？')
    if(result)
    {
      dispatch(deleteMember(memberId))
    }
    else
    {
      return false
    }
  }

  const meta =
  {
    title: SiteTitle,
  }

  const Floors =
  [
    {
      name: '顧客一覧',
      href: '/mypage/member_list'
    }
  ]

  return(
    <DocumentMeta {...meta}>
      <div id="customer_history_page">
        <BreadCrumb
          floors = { Floors }
        />
        <main className="member_edit_content">
          <h1>顧客一覧</h1>
          <section className="customer_search">
            <table>
              <tbody>
                <tr>
                  <td>顧客検索</td>
                </tr>
                <tr>
                  <td>
                    <p>顧客名（フリガナ）で検索できます。</p>
                    <div className="search_btn">
                      <input
                        type = "text"
                        name = "member_name"
                        value = {memberName}
                        onChange = {inputMemberName}
                      />
                      <button onClick={()=>searchMemberListBtn()}>検索</button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          <section className="customer_main">
            <div className="sort_box">
              <div className="box_left">
                <select
                 name = "sort_order"
                 value = {sortOrder}
                 onChange = {inputSortOrder}
                 >
                  <option value="">全て</option>
                  <option value="family_name_furigana ASC">フリガナ</option>
                  <option value="insert_datetime DESC">登録日が新しい順</option>
                  <option value="insert_datetime ASC">登録日が古い順</option>
                </select>
                <div>
                <button onClick={()=>sortMemberList()}>変更</button>
                </div>
              </div>
              <div className="right_box">
              <div>
                <button style={InsertButtonStyle} onClick={()=>dispatch(push(MypageDir+'/member_add'))}>新規登録</button>
              </div>
              </div>
            </div>
            <div className="customer_history_main">
              <table>
              <thead>
              <tr>
                <th>撮影日</th>
                <th>氏名</th>
                <th>登録日<br />更新日</th>
                <th>編集・削除</th>
              </tr>
            </thead>
              <tbody>
          {Array.isArray(members) && members.map((member, i)=>
          (
            (showCount * (paged - 1)) <= i && i < (showCount * paged) &&
            <tr key = {i}>
            <td className="shooting_date_box">
              {member.shooting_date !== ''?member.shooting_date:'-'}
            </td>
            <td className="name_box">
              <span className="margin_right_10px">{member.family_name !== ''?member.family_name:'-'}</span>
              <span>{member.first_name !== ''?member.first_name:'-'}</span>
              <br />
              <span className="margin_right_10px">{member.family_name_furigana !== ''?member.family_name_furigana:'-'}</span>
              <span>{member.first_name_furigana !== ''?member.first_name_furigana:'-'}</span>
            </td>
            <td className="datetime_box">
              {member.insert_datetime !== ''?member.insert_datetime:'-'}<br/>
              {member.update_datetime !== ''?member.update_datetime:'-'}
            </td>
            <td className="button_box">
              <button className="update_btn" onClick={()=>{window.location = RouteDir + '/mypage/member_edit/' + member.id}}>編集</button>
              <button className="delete_btn" onClick={()=>deleteConfirm(member.id)}>削除</button>
            </td>
          </tr>
          ))}
        </tbody>
              </table>
            </div>
          </section>
          <section className="under_menu_area">
            <div className="left_group">
            </div>
            <div className="right_group">
              <Paging
              publicPage = {true}
                length = {orderHistories.length}
                paged = {paged}
                showCount = {showCount}
                prevtext = {'前へ'}
                nexttext = {'次へ'}
                slug = {'mypage/order_history'}
              />
            </div>
          </section>
        </main>
      </div>
    </DocumentMeta>
  )
}

export default OrderHistory