// //開発環境
// export const HomeUrl = 'http://localhost:3000/pocket_dl_test/react/'
// export const HomeDir = '/pocket_dl_test'
// export const ApiDir = '/pocket_dl_test/api'
// export const JsDir = '/pocket_dl_test/js'
// export const ImagesDir = '/pocket_dl_test/images'
// export const RouteDir = '/pocket_dl_test/react'
// export const PublicImageDir = '/pocket_dl_test/react/images'
// export const AdminsDir = '/pocket_dl_test/react/admin'
// export const MypageDir = '/pocket_dl_test/react/mypage'
// export const NewsImageDir = '/pocket_dl_test/images/news'
// export const ItemImageDir = '/pocket_dl_test/images/items'
// // export const PhotoImageDir = '/pocket_dl_test/photo/picture'
// export const PhotoImageDir = '../../../../../photo/picture'

//本番環境
export const HomeUrl = 'https://www.photo-download-bridalpocket.com/'
export const RouteDir = ''
export const HomeDir = ''
export const ApiDir = '/api'
export const JsDir = '/js'
export const SvgDir = '/svg'
export const FontsDir = '/fonts'
export const ImagesDir = '/images'
export const PublicImageDir = '/images'
export const AdminsDir = '/admin'
export const MypageDir = '/mypage'
export const NewsImageDir = '/images/news'
export const ItemImageDir = '/images/items'
export const PhotoImageDir = '../../../../../../photo/picture'

//本番環境(ドメイン未設定)
// export const HomeUrl = 'https://bridalpocket1.aa0.netvolante.jp/pocket_dl/'
// export const RouteDir = '/pocket_dl'
// export const HomeDir = '/pocket_dl'
// export const ApiDir = '/pocket_dl/api'
// export const JsDir = '/pocket_dl/js'
// export const SvgDir = '/pocket_dl/svg'
// export const FontsDir = '/pocket_dl/fonts'
// export const ImagesDir = '/pocket_dl/images'
// export const PublicImageDir = '/pocket_dl/images'
// export const AdminsDir = '/pocket_dl/admin'
// export const MypageDir = '/pocket_dl/mypage'
// export const NewsImageDir = '/pocket_dl/images/news'
// export const ItemImageDir = '/pocket_dl/images/items'
// export const PhotoImageDir = '../../../../../../photo/picture'


// //ステージング環境
// export const HomeUrl = 'https://153.126.199.238/pocket_dl/'
// export const RouteDir = '/pocket_dl'
// export const HomeDir = '/pocket_dl'
// export const ApiDir = '/pocket_dl/api'
// export const JsDir = '/pocket_dl/js'
// export const SvgDir = '/pocket_dl/svg'
// export const FontsDir = '/pocket_dl/fonts'
// export const ImagesDir = '/pocket_dl/images'
// export const PublicImageDir = '/pocket_dl/images'
// export const AdminsDir = '/pocket_dl/admin'
// export const MypageDir = '/pocket_dl/mypage'
// export const NewsImageDir = '/pocket_dl/images/news'
// export const ItemImageDir = '/pocket_dl/images/items'
// // export const PhotoImageDir = '/pocket_dl_test/photo/picture'
// export const PhotoImageDir = '../../../../../../photo/picture'


//オンライン決済
//テスト決済環境
export const applicationId = 'sandbox-sq0idb-HYRCtQzPcVMk_P4urvjA2A'
export const locationId = 'LJNQ5WBEE7C0Y'
export const copyRight = '*******'


// 入力制限用　ここから =======================================================================

// パスワード検証用正規表現
export const REGEX_PASSWORD = /^[a-zA-Z0-9!"#$%&'()\*\+\-\.,\/:;<=>?@\[\\\]^_`{|}~]{10,20}$/;

// メールアドレス検証用正規表現
// 参考：https://www.javadrive.jp/regex-basic/sample/index13.html#section2
export const REGEX_MAIL_ADDRESS =
  /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

export const REGEX_ITEM_PRICE = /^[0-9]{1,8}$/; // 商品価格（8桁）
export const REGEX_POSTAGE = /^[0-9]{1,8}$/; // 送料（8桁）
export const REGEX_STOCK_QUANTITY = /^[-]?[0-9]{1,8}$/; // 在庫数（8桁）
// export const REGEX_STOCK_QUANTITY = /^(([-]?[1-9][0-9]{,7})|0)$/; // 在庫数（8桁）
export const REGEX_TAX_RATE = /^[0-9]{1,3}$/; // 税率

// 電話番号
// 参考：https://rapicro.com/%E9%9B%BB%E8%A9%B1%E7%95%AA%E5%8F%B7%E3%81%AE%E6%AD%A3%E8%A6%8F%E8%A1%A8%E7%8F%BE%E3%81%AF%EF%BC%9F/
export const REGEX_TEL_NUMBER = /^0[-0-9]{9,12}$/;

// 生年月日
export const REGEX_BIRTHDAY = /^(19[0-9]{2}|20[0-9]{2})-(0?[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;

// 入力制限用　ここまで =======================================================================


//エラー回避用古い情報
export const postUrl = 'https://sandbox.paygent.co.jp/v/u/request'
export const merchantId = '49225'
export const merchantName = 'ブライダルポケット画像DLシステム'