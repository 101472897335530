import { REGEX_MAIL_ADDRESS, REGEX_PASSWORD } from './common';
import { config } from './config';

// 小数点第2位以下を切り捨て（JavaScript小数誤差対策）
export const truncateDecimal = (decimalNum) => {
  // console.log(decimalNum);
  let tmpNum = decimalNum * 10;
  tmpNum = Math.floor(tmpNum);
  return tmpNum / 10;
};

// 商品税込価格を指定の計算方式で計算した値を返す
export const calcItemTaxIncludedPrice = (itemPrice, taxRate) => {
  // 税込金額計算後、小数点第2位以下切り捨て
  const tmpNum = truncateDecimal(Number(itemPrice) * (1 + Number(taxRate) / 100));
  switch (config.ITEM_PRICE_CALCULATION_TYPE) {
    case 'FLOOR':
      return Math.floor(tmpNum); /* 切り捨て */
    case 'ROUND':
      return Math.round(tmpNum); /* 四捨五入 */
    case 'CEIL':
      return Math.ceil(tmpNum); /* 切り上げ */
    default:
      return 0;
  }
};

// パスワード要件を満たしているか検証
export const isPasswordValid = (password) => {
  if (!password) {
    return false;
  }

  return REGEX_PASSWORD.test(password);
};

// メールアドレス要件を満たしているか検証
export const isMailAddressValid = (mailAddress) => {
  if (!mailAddress) {
    return false;
  }

  return REGEX_MAIL_ADDRESS.test(mailAddress);
};

/* 日付の比較する関数 */
/**
 * date2不可日がdate1開始日が同じかどうか判定
 * @param {date} date1 開始日
 * @param {date} date2 不可日
 * @returns {boolean} 検証結果
 */
export function areDatesEqual(date1, date2) {
  return date1.getFullYear() === date2.getFullYear() &&
         date1.getMonth() === date2.getMonth() &&
         date1.getDate() === date2.getDate();
}

/**
 * date2不可日がdate1開始日以降かどうか判定
 * @param {date} date1 開始日
 * @param {date} date2 不可日
 * @returns {boolean} 検証結果
 */
export function laterThanDates(date1, date2) {
  return date1.getFullYear() <= date2.getFullYear() &&
         date1.getMonth() <= date2.getMonth() &&
         date1.getDate() <= date2.getDate();
}

/**
 * date2不可日がdate1開始日以前かどうか判定
 * @param {date} date1 開始日
 * @param {date} date2 不可日
 * @returns {boolean} 検証結果
 */
export function beforeDates(date1, date2) {
  return date1.getFullYear() >= date2.getFullYear() &&
         date1.getMonth() >= date2.getMonth() &&
         date1.getDate() >= date2.getDate();
}

/**
​
指定した Date オブジェクトの時刻を 0 時 0 分 0 秒 0 ミリ秒にリセットする。
2つの年月日秒を比較するために使用
@param {Date} date リセットする Date オブジェクト
@returns {Date} リセットされた Date オブジェクト
*/
export function resetTime(date) {
  date.setHours(0); //時間を0時にリセット
  date.setMinutes(0); //分を0分にリセット
  date.setSeconds(0); //秒を0秒にリセット
  date.setMilliseconds(0); //ミリ秒をリセット
  return date;
}