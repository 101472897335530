import React, { useEffect, useCallback, useState } from "react"
import { updateMemberForPublic,deleteImageForPublic } from '../../reducks/members/operations'
import { changeLoading } from '../../reducks/pageInfos/operations'
import DocumentMeta from 'react-document-meta'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import  {ImageSelectyButton, UploadFile} from '../../components/UIkit'
import axios from 'axios'
import { ApiDir, RouteDir, JsDir, PhotoImageDir,HomeUrl } from "../../common"
import { BreadCrumb } from '../../components'
import { SiteTitle } from './common'
import importScript from '../../components/hooks/ImportScript'
import queryString from 'query-string';


const ItemList = (props) =>
{
  // 郵便番号情報の読み込み
  // importScript(JsDir + '/ajaxzip3.js')

  //ローディング
  const loading = useSelector(state => state.pageInfos.loading)

  const pageMemberId = props.match.params.id
  const members = useSelector(state => state.members.list)
  const dispatch = useDispatch()
  const photographerId = useSelector(state => state.photographers.photographer_id)
  const photographerName = useSelector(state => state.photographers.name)
  const [photographer, setPhotographerId] = useState([])
  const [image, setImage] = useState([]);

  //ページング用 パラメータの取得
  const queryParam = queryString.parse(props.location.search)
  const [paged, setPaged] = useState('')
  const [showCount, setShowCount] = useState('')

  //姓入力
  const [familyNameValue, setFamilyNameValue] = useState('')
  const inputFamilyNameValue = useCallback((event) =>
  {
    inputValueCheck()
    setFamilyNameValue(event.target.value)
  }, [setFamilyNameValue])

  //名入力
  const [firstNameValue, setFirstNameValue] = useState('')
  const inputFirstNameValue = useCallback((event) =>
  {
    inputValueCheck()
    setFirstNameValue(event.target.value)
  }, [setFirstNameValue])

  //セイ入力
  const [familyNameFuriganaValue, setFamilyNameFuriganaValue] = useState('')
  const inputFamilyNameFuriganaValue = useCallback((event) =>
  {
    inputValueCheck()
    setFamilyNameFuriganaValue(event.target.value)
  }, [setFamilyNameFuriganaValue])

  //メイ入力
  const [firstNameFuriganaValue, setFirstNameFuriganaValue] = useState('')
  const inputFirstNameFuriganaValue = useCallback((event) =>
  {
    inputValueCheck()
    setFirstNameFuriganaValue(event.target.value)
  }, [setFirstNameFuriganaValue])

  //メールアドレス入力
  const [mailAddressValue, setMailAddressValue] = useState('')
  const inputMailAddressValue = useCallback((event) =>
  {
    inputValueCheck()
    setMailAddressValue(event.target.value)
  }, [setMailAddressValue])

  //撮影日の入力
  const [ShootingDateValue, setShootingDateValue] = useState('')
  const inputShootingDateValue = useCallback((event) =>
  {
    inputValueCheck()
    setShootingDateValue(event.target.value)
  }, [setShootingDateValue])


  //メールアドレス入力
  const [reMailAddressValue, setReMailAddressValue] = useState('')
  const inputReMailAddressValue = useCallback((event) =>
  {
    inputValueCheck()
    setReMailAddressValue(event.target.value)
  }, [setReMailAddressValue])

  //生年月日入力
  const [birthdayValue, setBirthdayValue] = useState('')
  const inputBirthdayValue = useCallback((event) =>
  {
    inputValueCheck()
    setBirthdayValue(event.target.value)
  }, [setBirthdayValue])

  //郵便番号入力
  const [potalCodeValue, setPostalcodeValue] = useState('')
  const inputPostalCodeValue = useCallback((event) =>
  {
    inputValueCheck()
    setPostalcodeValue(event.target.value)
  }, [setPostalcodeValue])

  //住所入力
  const [addressValue, setAddressValue] = useState('')
  const inputAddressValue = useCallback((event) =>
  {
    inputValueCheck()
    setAddressValue(event.target.value)
  }, [setAddressValue])

  //電話番号入力
  const [telnumberValue, setTelnumberValue] = useState('')
  const inputTelnumberValue = useCallback((event) =>
  {
    inputValueCheck()
    setTelnumberValue(event.target.value)
  }, [setTelnumberValue])

  //メルマガ配信希望入力
  const [mailMagazineFlagValue, setMailMagazineFlagValue] = useState('1')
  const inputMailMagazineFlagValue = useCallback((event) =>
  {
    inputValueCheck()
    setMailMagazineFlagValue(event.target.value)
  }, [setMailMagazineFlagValue])

  //カメラマン名の入力
  const [PhotographerIdValue, setPhotographerIdValue] = useState('')
  const inputPhotographerIdValue = useCallback((event) =>
  {
    setPhotographerIdValue(event.target.value)
  }, [setPhotographerIdValue])

  //URLの入力
  const [MembersUrlValue, setMembersUrlValue] = useState('')
  const inputMembersUrlValue = useCallback((event) =>
  {
    setMembersUrlValue(event.target.value)
  }, [setMembersUrlValue])

  //ステータスの入力
  const [StatusValue, setStatusValue] = useState('1')
  const inputStatusValue = useCallback((event) =>
  {
    inputValueCheck()
    setStatusValue(event.target.value)
  }, [setStatusValue])

  //入力値が入っているかの確認
  const inputValueCheck = () =>
  {
    // const inputValueOfFamilyName = document.getElementsByName('family_name')
    // const inputValueOfFirstName = document.getElementsByName('first_name')
    // const inputValueOfFamilyNameFurigana = document.getElementsByName('family_name_furigana')
    // const inputValueOfFirstNameFurigana = document.getElementsByName('first_name_furigana')
    // const inputShootingDateValue = document.getElementsByName('shooting_date')
    // const inputMembersUrlValue = document.getElementsByName('members_url')
    // if(inputValueOfFamilyName[0].value !== '' && inputValueOfFirstName[0].value !== '' && inputValueOfFamilyNameFurigana[0].value !== '' && inputValueOfFirstNameFurigana[0].value !== '' && inputShootingDateValue[0].value !== '' && inputMembersUrlValue[0].value !== '')
    // {
    //   document.getElementById('insert_btn').classList.remove('desabled')
    // }
    // else
    // {
    //   document.getElementById('insert_btn').classList.add('desabled')
    // }
    const formElem = document.getElementById('update_form');
    for (let i = 0; i < formElem.elements.length; i++) {
      const elem = formElem.elements[i];
      if (elem.tagName === 'INPUT') {
        /* inputタグのみチェック */
        if (elem.hasAttribute('required')) {
          if (elem.value.trim() == '') {
            document.getElementById('insert_btn').classList.add('desabled');
            return;
          }
        }
      }
    }
    document.getElementById('insert_btn').classList.remove('desabled');
  }


  useEffect(()=>
  {
    let params = new URLSearchParams();

    //会員情報の取得
    params = new URLSearchParams();
    params.append('member_id',pageMemberId);
    params.append('photographer_id',photographerId);
    params.append('formkey','selectkey');
    axios.post(ApiDir+'/selectMemberListPublic.php',params)
    .then(function(response){
      console.log(response.data)
      setFamilyNameValue(response.data[0].family_name)
      setFirstNameValue(response.data[0].first_name)
      setFamilyNameFuriganaValue(response.data[0].family_name_furigana)
      setFirstNameFuriganaValue(response.data[0].first_name_furigana)
      setShootingDateValue(response.data[0].shooting_date)
      setPhotographerIdValue(response.data[0].photographer_id)
      setMembersUrlValue(response.data[0].members_url)
      setStatusValue(response.data[0].status)
      inputValueCheck()
    })
    .catch(function(error){
      console.log(error)
      return
    })


      //カメラマン名取得
      params = new URLSearchParams();
      params.append('formkey','selectkey');
      axios.post(ApiDir+'/selectPhotographerList.php',params)
      .then(function(response){
        console.log(response.data)
        setPhotographerId(response.data)
      })
      .catch(function(error){
        console.log(error)
        return
      })

    //画像取得
    params = new URLSearchParams();
    params.append('member_id',pageMemberId);
    params.append('formkey','selectkey');
    axios.post(ApiDir+'/selectMemberImage.php',params)
    .then(function(response){
      console.log(response.data)
      setImage(response.data)
    })
    .catch(function(error){
      console.log(error)
      return
    })

  },[pageMemberId])

  //送信ボタン押下時の処理
  const sendFormData = () =>
  {
    
    //ローディング開始
    dispatch(changeLoading(true))

    //form情報の取得
    const formElement = document.getElementById('update_form')
    const formData = new FormData(formElement);

      //formkeyの追加
      formData.append('member_id',pageMemberId);
      formData.append('status',StatusValue);
      formData.append('formkey','updatekey');
      dispatch(updateMemberForPublic(formData))
  }

  //住所自動入力
  // const {AjaxZip3} = window
  // const postalAutoInput = ()=>
  // {
  //   AjaxZip3.zip2addr('postal_code','','address','address')
  //   setTimeout(()=>{
  //     const addressElement = document.getElementsByName('address')
  //     const addressValue = addressElement[0].value
  //     setAddressValue(addressValue)
  //   },100)
  // }

  // 削除処理 add 20220921
  const remove = (index,e,optionId) => 
  {
    const ret =window.confirm('この画像を削除しますか？');
    if(!ret){
      return false
    }else{
      image.splice(index, 1);
      setImage([...image]);
      dispatch(deleteImageForPublic(optionId))
    }
    
  };

  const meta =
  {
    title: SiteTitle,
  }

  const Floors =
  [
    {
      name: '会員情報変更',
      href: '/mypage/member_edit/' + pageMemberId
    }
  ]

  return(
    <DocumentMeta {...meta}>
      <div id="member_edit_page">
        <main className="member_edit_content form_type_1">
          <h1>顧客編集</h1>
          <form encType="multipart/form-data" method="post" id="update_form" onSubmit={(e)=>e.preventDefault()}>
            <table>
              <thead></thead>
              <tbody>
                <tr>
                  <th>氏名<span className="required">必須</span></th>
                  <td>
                    <div className="family_name_area">
                      <span>姓</span>
                      <input
                      type = "text"
                      name = {'family_name'}
                      className = ""
                      value = {familyNameValue}
                      onChange = {inputFamilyNameValue}
                      required
                    />
                    </div>
                    <div className="first_name_area">
                      <span>名</span>
                      <input
                        type = "text"
                        name = {'first_name'}
                        value = {firstNameValue}
                        onChange = {inputFirstNameValue}
                        required
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>フリガナ<span className="required">必須</span></th>
                  <td>
                    <div className="family_name_area">
                      <span>セイ</span>
                      <input
                        type = "text"
                        name = {'family_name_furigana'}
                        value = {familyNameFuriganaValue}
                        onChange = {inputFamilyNameFuriganaValue}
                        required
                      />
                    </div>
                    <div className="first_name_area">
                      <span>メイ</span>
                      <input
                        type = "text"
                        name = {'first_name_furigana'}
                        value = {firstNameFuriganaValue}
                        onChange = {inputFirstNameFuriganaValue}
                        required
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>撮影日<span className="required">必須</span></th>
                  <td>
                    <div className="family_name_area">
                      <span></span>
                      <input
                      type = "text"
                      name = {'shooting_date'}
                      value = {ShootingDateValue}
                      onChange = {inputShootingDateValue}
                      required
                    />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>顧客ページURL</th>
                  <td>
                    <span>{HomeUrl+'download/'+MembersUrlValue}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          <div className="button_area upload_area">
            <p>※<span className="red">Zip形式</span>のファイルをアップロードしてください。</p>
            <UploadFile/>
          {/* <button id = "send_btn" className="desabled" onClick={()=>sendFormData()}>画像を追加</button> */}
          </div>
          <div className="media_area">
            {/* <ImageSelectyButton/> */}
            
              <div className="upload_box_state">
              {Array.isArray(image) && image.map((option, index) => (
              <div className="preview" key={option.id}>
                <button 
                  id={'image_'+option.id}
                  className="image_delete"
                  onClick={(e)=>{remove(index,e,option.id);}}
                ></button>
                <div>
                  {/* <img src={PhotoImageDir+'/'+option.member_id+option.path}></img> */}
                  <img src={ApiDir+'/image.php?img=/'+option.member_id+option.path}></img>
                  <p>{option.path.substr(1)}</p>
                </div>
              </div>
              ))}
              </div>
          </div>
          </form>
          <div className="button_area">
          <button id = "insert_btn" className="desabled" onClick={()=>sendFormData()}>登 録</button>
        </div>
        <div id="loading_area" className={loading===true?'':'hidden'}>
          <div className="loader">Loading...</div>
        </div>
        </main>
      </div>
    </DocumentMeta>
  )
}

export default ItemList