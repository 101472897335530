import React, { useCallback, useEffect, useState } from "react"
import { Route, Switch, useLocation } from "react-router";
import { insertFloor, insertH1 } from '../../reducks/pageInfos/operations'
import { deleteFavorite, downloadImage, downloadImageFull } from '../../reducks/members/operations'
import { Paging } from '../../components/UIkit'
import DocumentMeta from 'react-document-meta'
import { BreadCrumb } from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { SiteTitle } from './common'
import { ApiDir, ImagesDir, RouteDir, ItemImageDir, PhotoImageDir, HomeUrl } from '../../common'
import queryString from 'query-string';

const Download = (props) =>
{
  // URLを取得
  const location = useLocation();
  // const url = window.location.href
  const url = props.match.params.serial
  // console.log(PhotoImageDir)
  
  const dispatch = useDispatch()

  //ページング用 パラメータの取得
  const queryParam = queryString.parse(props.location.search)
  const thisUrl = props.location.pathname + props.location.search
  let pageUrl = ''
  if(queryParam.paged)
  {
    pageUrl = thisUrl.replace(RouteDir+'/', '')
    pageUrl = pageUrl.replace('?paged='+queryParam.paged, '')
    pageUrl = pageUrl.replace('&paged='+queryParam.paged, '')
    pageUrl = pageUrl.replace('&', '')
  }
  else
  {
    pageUrl = thisUrl.replace(RouteDir+'/', '')
  }
  const [paged, setPaged] = useState('')
  const [showCount, setShowCount] = useState('')


  // 会員情報
  const [itemInfo, setItemInfo] = useState('')
  // 会員画像情報
  const [itemImages, setItemImages] = useState([])
  const pageMemberId = itemInfo.id

  useEffect(()=>{

    //会員情報を取得
    let params = new URLSearchParams();
    params.append('members_url',url);
    params.append('formkey','selectkey');
    axios.post(ApiDir+'/selectPublicMember.php',params)
    .then(function(response){
      console.log(response.data)
      setItemInfo(response.data[0])
    })
    .catch(function(error){
      console.log(error)
      return
    })

    const pageMemberId = itemInfo.id
    // 画像情報を取得
    params.append('members_id',pageMemberId);
    params.append('formkey','selectkey');
    axios.post(ApiDir+'/selectPublicMemberImage.php',params)
    .then(function(response){
      console.log(response.data)
      setItemImages(response.data)
    })
    .catch(function(error){
      console.log(error)
      return
    })

    if(queryParam.paged)
    {
      setPaged(queryParam.paged)
    }
    else
    {
      setPaged(1)
    }
    setShowCount(2) //１ページに表示する数


  },[dispatch, itemInfo.id, itemImages.id,queryParam.paged])
  // ここまで　20220624 , queryParam.paged add

  // // 画像ダウンロードボタン押下時の処理
  // const individualDownload = (image_id) =>
  // {
  //   // const member_id = response.data[0].member_id
  //     // const url = '../photo/picture/26/01.jpg'
  //     // console.log(url)
  //     // const matches = url.match(/[A-Za-z0-9\-_]+\.\w+$/);
  
  //     // fetch(url, {
  //     //   method: 'POST',
  //     //   headers: {},
  //     // })
  //     //   .then((response) => {
  //     //     response.arrayBuffer().then((buffer) => {
  //     //       const url = window.URL.createObjectURL(new Blob([buffer]));
  //     //       const link = document.createElement('a');
  //     //       link.href = url;
  //     //       link.setAttribute('download', matches[0]);
  //     //       document.body.appendChild(link);
  //     //       link.click();
  //     //     });
  //     //   })
  //     //   .catch((err) => {
  //     //     console.log(err);
  //     //   });
  //   //form情報の取得
  //   const formElement = document.getElementById('update_form')
  //   const formData = new FormData(formElement);
    
  //   // console.log(formData)
  //   // formData.append('member_id',member_id)
  //   formData.append('image_id',image_id)
  //   // formData.append('image_path',image_path)
  //   // formData.append('member_id',member_id)

  //   // formData.append('image_path',image_path)
    
  //   //formkeyの追加
  //   formData.append('formkey','updatekey')
  //   dispatch(downloadImage(formData))
  // }

  // // 一括ダウンロードボタンを押下時の処理
  // const batchDownload = () =>
  // {
  //   //form情報の取得
  //   const formElement = document.getElementById('download_form')
  //   const formData = new FormData(formElement); 
  //   formData.append('member_id',pageMemberId)
  //   // console.log(formData)

  //   // formData.append('image_path',image_path)
    
  //   //formkeyの追加
  //   formData.append('formkey','updatekey')
  //   dispatch(downloadImageFull(formData))
  //   // console.log(formData)
  // }

  // // 選択ダウンロードボタンを押下時の処理
  // const selectDownload = () =>
  // {
  //   console.log("log2")
  // }

  const meta =
  {
    title: SiteTitle,
  }

  const Floors =
  [
    {
      name: '画像ダウンロード',
      href: '/download'
    }
  ]

  const downloadImageStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/slider_1.jpg')`
  }

  return(
    <DocumentMeta {...meta}>
      <div id="download_page">
        {/* <BreadCrumb
          floors = { Floors }
        /> */}
        <main className="download_content">
          <h1>画像ダウンロード</h1>
          <section className="flex_box">
            <div className="left_content">
              <h2>{itemInfo.family_name} {itemInfo.first_name}様</h2>
              <p>撮影日：{itemInfo.shooting_date}</p>
            </div>
            <div className="right_content">
              {/* <form action={ApiDir+'/downloadZip.php'} encType="multipart/form-data" method="post" id="download_form1">
                <input type="submit" value={"一括ダウンロード"}/>
                <input type="hidden" name="download-type" value={"all"}/>
              </form> */}
            </div>
            {/* <p>※複数枚の写真をダウンロードしたい場合は、写真にチェックを入れて「選択ダウンロード」ボタンを押してください。</p> */}
          </section>
          <section className="image_sec" id="notes_area">
            <div className="text_wrap">
              <div className="text_box">
                <h3>【ご利用にあたっての注意】</h3>
                <ul>
                  <li>※ 当サービスにて画像をダウンロードされるにあたり、様々な環境が必要となります。<br></br>場合によっては、ダウンロードに時間がかかったり、ご利用できない場合もありますので、必ず以下の動作環境をご確認の上、ご利用ください。</li>
                  <li>※ 画像ダウンロードページの閲覧やダウンロードには大量のデータ(パケット)通信を行いますので、携帯・通信キャリア各社にて通信料が発生します。</li>
                  <li className="red bold">※ スマートフォンやタブレットをお使いの場合は、Wi-fi環境でのご利用を推奨します。</li>
                  <li>※ なお、発生したデータ通信費用について弊社は一切の責任を負いかねます。予めご了承ください。</li>
                  <li>※ 「一括ダウンロード」は、ZIP形式に圧縮されてダウンロードされます。<br></br>1枚あたりの画像サイズも大きいですので、「一括ダウンロード」をされる場合は、通信環境の良い環境でのご利用、パソコンでのダウンロードを推奨します。</li>
                  <li>※ スマーフォンで「一括ダウンロード」をされる場合は、必ず「ZIPファイルの保存場所と解凍方法」の内容をご確認の上、ご利用ください。</li>
                  {/* <li>※ 複数枚の写真をダウンロードしたい場合は、写真にチェックを入れて「選択ダウンロード」ボタンを押してください。</li> */}
                  <li className="link">
                    <a href="#howto">ZIPファイルの保存場所と解凍方法</a>
                  </li>
                </ul>
              </div>
            </div>         
            {/* <form action={ApiDir+'/downloadZip.php'} encType="multipart/form-data" method="post" id="download_form">
            <div className="right_content">
              <input type="submit" value={"選択ダウンロード"}/>
              <input type="hidden" name="download-type" value={"select"}/>
            </div>
            </form>
             */}
            <div className="right_content">
              <form action={ApiDir+'/downloadZip.php'} encType="multipart/form-data" method="post" id="download_form1">
                <input type="submit" value={"一括ダウンロード"}/>
                <input type="hidden" name="download-type" value={"all"}/>
              </form>
            </div>
            <div className="image_area">
              {Array.isArray(itemImages) && itemImages.map((image, i) =>(
                <form action={ApiDir+'/download.php'} encType="multipart/form-data" method="post" id={"update_form_"+image.id} key={i}>
                {/* {Array.isArray(itemImages) && itemImages.map((image, i) =>( */}
                <div className="box1" >
                  <label htmlFor="check" className="chk">
                    {/* <input type="checkbox" name="check[]" value={image.path} form="download_form"/> */}
                    
                    {/* <img src={PhotoImageDir+'/'+image.member_id+image.path} alt=""  className="item_image_area" /> */}
                    <div className="preview">
                      {/* <p>{image.path.substr(1)}</p> */}
                      <img src={ApiDir+'/image.php?img=/'+image.member_id+image.path} alt="" id={"targetimg_"+image.id}/>
                      <span>{image.path.substr(1)}</span><p id="output"></p>
                    </div>
                    {/* <div className="item_image_area" style={image.path!==null?{backgroundImage:`url('${PhotoImageDir}${'/'+image.member_id}${image.path}')`}:{backgroundImage:`url('${process.env.PUBLIC_URL}/images/noimage.jpg')`}}></div> */}
                  </label>
                  <input type="hidden" name="image_id" value={image.path}/>
                  <input type="hidden" name="member_id" value={image.member_id}/>
                  <input type="hidden" form="download_form" name="members_id" value={image.member_id}/>
                  <input type="hidden" form="download_form" name="images_id" value={image.path}/>
                  <input type="hidden" form="download_form1" name="members_id" value={image.member_id}/>
                  <input type="hidden" form="download_form1" name="images_id" value={image.path}/>
                  {/* <button type="submit" className="download">ダウンロード</button> */}
                </div>
                </form>
              ))}
              
              {/* <img src={ApiDir+'/image.php'} alt="" /> */}
              {/* <div className="box1">
                <label htmlFor="" className="chk">
                  <input type="checkbox" name="check[]" />
                </label>
                <div style={downloadImageStyle}></div>
                <a href="../images/photo/slider_1.jpg" target="_blank">ダウンロード</a>
              </div> */}
            </div>
            {/* <div className="right_group">
              <Paging
              publicPage = {true}
                length = {itemImages.length}
                paged = {paged}
                showCount = {showCount}
                prevtext = {'前へ'}
                nexttext = {'次へ'}
                slug = {pageUrl}
              />
            </div> */}
          </section>
          <section id="howto">
            <div className="text_wrap">
              <div className="text_box">
                <h3>【ZIPファイルの保存場所・解凍方法】</h3>
                <p>「一括ダウンロード」複数枚の画像を選択して「選択ダウンロード」をした場合、ZIPファイルでダウンロードされます。</p>
                <p>AndroidやiPhoneでZIP等のファイルを取得した場合、ファイルアプリ内のダウンロードフォルダに保存されています。<br></br>「リンク先のファイルをダウンロード」した写真・動画等も、同様にファイルアプリ内に保存されており、iPhone／iPadなどの場合、初期設定では「iCloud Drive」に保存されるようになっています。</p>
                <h4>【 iPhone／iPadの場合 】</h4>
                <ul>
                  <li>
                    ① iPhone／iPadのホーム画面から、フォルダの形をしたアイコンの「ファイル」を開き、「iCloud Drive」を開きます。
                    <img src={process.env.PUBLIC_URL + '/images/step01.png'} alt="" />
                  </li>
                  <li>
                    ② ダウンロードフォルダを開きます。
                    <img src={process.env.PUBLIC_URL + '/images/step02.png'} alt="" className="w90" />
                  </li>
                </ul>
                <h5>【ZIPファイルの開き方】</h5>
                <ul>
                  <li>
                    ① ファイル App を開いて、圧縮解除したい ZIPファイル（file_(日時).zipという名前のファイルです）を探します。
                    <img src={process.env.PUBLIC_URL + '/images/step03.png'} alt="" className="w90"/>
                  </li>
                  <li>② ZIPファイルをタップします。</li>
                  <li>
                    ③ 中にファイルが入ったフォルダが作成されます。
                    <img src={process.env.PUBLIC_URL + '/images/step05.png'} alt="" className="w90"/>
                  </li>
                  <li>④ フォルダをタップして開きます。</li>
                </ul>
                <div className="link">
                  <a href="#download_page">ぺージ上部に戻る</a>
                </div>
              </div>
              <div className="text_box">
                <h4>【 Androidの場合 】</h4>
                <ul>
                  <li>
                    ① Android 端末で、「Files by Google Files」を開きます。
                    <img src={process.env.PUBLIC_URL + '/images/an_step01.png'} alt="" />
                  </li>
                  <li>② 「ダウンロード」フォルダを開きます。</li>
                </ul>
                <h5>【ZIPファイルの開き方】</h5>
                <ul>
                  <li>
                    ① 開きたいzip ファイルを選択します。
                    <img src={process.env.PUBLIC_URL + '/images/an_step02.png'} alt="" className="w90"/>
                  </li>
                  <li>
                    ② そのファイルの内容を示すポップアップが表示されるので、[解凍] をタップします。
                    <img src={process.env.PUBLIC_URL + '/images/an_step03.png'} alt="" className="w90"/>
                  </li>
                  <li>
                    ③ 解凍されたファイルのプレビューが表示されます。<br></br>解凍後に .zip ファイルを削除する場合は、[ZIP ファイルの削除] チェックボックスをオンにします。
                    <img src={process.env.PUBLIC_URL + '/images/an_step04.png'} alt="" className="w90"/>
                  </li>
                  <li>④ [完了] をタップします。</li>
                  <li>⑤ 解凍されたファイルは、元の .zip ファイルと同じフォルダに保存されます。</li>
                </ul>
                <div className="link">
                  <a href="#download_page">ぺージ上部に戻る</a>
                </div>
              </div>
              <div className="text_box">
                <h3>【動作推奨環境】</h3>
                <p>容量の大きい画像や、大量のデータを扱います。動作環境をご確認の上ご利用ください。</p>
                <ul>
                  <li>
                    - パソコン環境（OS・ブラウザ）のご確認
                    <ul>
                      <li>OS： Windows 10、MacOS最新版</li>
                      <li>対応ブラウザ：Google Chrome最新版、Safari最新版</li>
                    </ul>
                  </li>
                  <li>
                    - スマーフォン環境（OS・ブラウザ）のご確認
                    <ul>
                      <li>OS：Android最新版、iOS最新版</li>
                      <li>対応ブラウザ：Google Chrome最新版、Safari最新版</li>
                    </ul>
                  </li>
                  <li>
                    - インターネット環境のご確認
                    <ul>
                      <li>大量のデータを扱います。通信速度が速ければより快適に画像をダウンロードすることが出できます。</li>
                      <li>WiFi環境など安定した通信環境を推奨します。</li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="link">
                <a href="#download_page">ぺージ上部に戻る</a>
              </div>
            </div>
          </section>
        </main>
      </div>
    </DocumentMeta>
  )
}

export default Download