import React, {  useCallback, useEffect, useState } from "react"
import { Paging } from '../../components/UIkit'
import { push } from 'connected-react-router'
import { selectPhotographerList, searchPhotographerList, deleteAdmin, bulkOperationPhotographer, deletePhotographer } from '../../reducks/photographers/operations'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { AdminsDir, ApiDir, ImagesDir } from '../../common'
import queryString from 'query-string';


const PhotographerList = (props) =>
{
  //ページング用 パラメータの取得
  const queryParam = queryString.parse(props.location.search)

  const dispatch = useDispatch()
  const photographers = useSelector(state => state.photographers.list)

  const [paged, setPaged] = useState('')
  const [showCount, setShowCount] = useState('')

  //検索カメラマン名の入力
  const [photographerName, setPhotographerName] = useState('')
  const inputPhotographerName = useCallback((event) =>
  {
    setPhotographerName(event.target.value)
  }, [setPhotographerName])

  //表示順の入力
  const [sortOrder, setSortOrder] = useState('id DESC')
  const inputSortOrder = useCallback((event) =>
  {
    setSortOrder(event.target.value)
  }, [setSortOrder])

  //一括操作の入力
  const [bulkOperation, setBulkOperation] = useState('')
  const inputBulkOperation = useCallback((event) =>
  {
    setBulkOperation(event.target.value)
  }, [setBulkOperation])

  useEffect(()=>
  {
    //カメラマンリストの取得、セット
    const selectPhotographerprops = {
      Limit: -1,
      Offset: 0,
      Sort: sortOrder
    }
    dispatch(selectPhotographerList(selectPhotographerprops))
    if(queryParam.paged)
    {
      setPaged(queryParam.paged)
    }
    else
    {
      setPaged(1)
    }
    setShowCount(50) //１ページに表示する数

  },[dispatch, queryParam.paged])

  const InsertButtonStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/insert_icon.png')`
  }

  const sortPhotographerList = () =>
  {
    //カメラマンリストの取得、セット
    const selectPhotographerprops = {
      Limit: -1,
      Offset: 0,
      Sort: sortOrder
    }
    dispatch(selectPhotographerList(selectPhotographerprops))
    if(queryParam.paged)
    {
      setPaged(queryParam.paged)
    }
    else
    {
      setPaged(1)
    }
    return
  }

  // カメラマン情報検索
  const searchPhotographerListBtn = () =>
  {
    //カメラマンリストの取得、セット
    const searchPhotographerprops = {
      photographerName: photographerName
    }
    dispatch(searchPhotographerList(searchPhotographerprops))
    console.log(searchPhotographerprops)
    if(queryParam.paged)
    {
      setPaged(queryParam.paged)
    }
    else
    {
      setPaged(1)
    }
  }
  
  // カメラマン情報削除
  const deleteConfirm = (PhotographerId) =>
  {
    const result = window.confirm('カメラマン情報を削除してよろしいですか？')
    if(result)
    {
      dispatch(deletePhotographer(PhotographerId))
    }
    else
    {
      return false
    }
  }

  const bulkOperationBtn = () =>
  {
    const selectValue = document.getElementById('select_bulk_operation').value;
    const selectCheckbox = document.querySelectorAll("input[name=select_checkbox]:checked");
    let selectCheckboxValue = [];
    if(selectCheckbox)
    {
      for(let i=0;i<selectCheckbox.length;i++)
      {
        selectCheckboxValue.push(selectCheckbox[i].value)
      }
    }
    dispatch(bulkOperationPhotographer(selectValue, selectCheckboxValue))
  }


  return(
    <main id="photographer_list_page">
      <h2 className="page_title"><span>カメラマン一覧</span></h2>
      <section className="search_area box_type_1">
        <div className="title_area">
          <h3>カメラマン検索</h3>
        </div>
        <div className="content_area">
          <dl>
            <dt>氏名（フリガナ）</dt>
            <dd>
              <input
                type = "text"
                name = "photographer_name"
                value = {photographerName}
                onChange = {inputPhotographerName}
              />
            </dd>
            <dd className="right">
              <button onClick={()=>searchPhotographerListBtn()}>検索</button>
            </dd>
          </dl>
        </div>
      </section>
      <section className="sort_menu_area">
        <div className="left_group">
          <div>
            <select
              name = "sort_order"
              value = {sortOrder}
              onChange = {inputSortOrder}
            >
              <option value="">全て</option>
              <option value="name_furigana ASC">氏名(フリガナ)</option>
              <option value="insert_datetime DESC">登録日が新しい順</option>
              <option value="insert_datetime ASC">登録日が古い順</option>
            </select>
          </div>
          <div>
            <button onClick={()=>sortPhotographerList()}>変更</button>
          </div>
        </div>
        <div className="right_group">
          <div>
            <button style={InsertButtonStyle} onClick={()=>dispatch(push(AdminsDir+'/photographers/add'))}>新規登録</button>
          </div>
        </div>
      </section>
      <section className="list_area">
        <table>
          <thead>
            <tr>
              {/* <th>選択</th> */}
              <th>状態</th>
              <th>氏名</th>
              <th>メールアドレス</th>
              <th>登録日<br />更新日</th>
              <th>編集<br />削除</th>
            </tr>
          </thead>
          <tbody>
          {Array.isArray(photographers) && photographers.map((photographer, i)=>
          (
            (showCount * (paged - 1)) <= i && i < (showCount * paged) &&
            <tr key = {i}>
            {/* <td className="checkbox_box"><input type="checkbox" name="select_checkbox" value={photographer.id}/></td> */}
            <td className="status_box">
              {/* {admin.authority_name !== ''?admin.authority_name:'-'}<br/> */}
              {photographer.status === '0' && '無効'}
              {photographer.status === '1' && '有効'}
            </td>
            <td className="name_box">
              {photographer.name_furigana !== ''?photographer.name_furigana:'-'}<br/>
              {photographer.name !== ''?photographer.name:'-'}
            </td>
            <td className="mail_address_box">
              {photographer.mail_address !== ''?photographer.mail_address:'-'}
            </td>
            {/* <td className="department_box">
              {admin.department_name !== ''?admin.department_name:'-'}
            </td> */}
            <td className="datetime_box">
              {photographer.insert_datetime !== ''?photographer.insert_datetime:'-'}<br/>
              {photographer.update_datetime !== ''?photographer.update_datetime:'-'}
            </td>
            <td className="button_box">
              <button className="update_btn" onClick={()=>{window.location = AdminsDir + '/photographers/edit/' + photographer.id}}>編集</button>
              <button className="delete_btn" onClick={()=>deleteConfirm(photographer.id)}>削除</button>
            </td>
          </tr>
          ))}
        </tbody>
        </table>
      </section>
      <section className="under_menu_area">
        {/* <div className="left_group">
          <div>
            <select
              id = "select_bulk_operation"
              name = "bulk_operation"
              value = {bulkOperation}
              onChange = {inputBulkOperation}
            >
              <option value="">一括操作</option>
              <option value="delete">削除</option>
              <option value="status_0">無効</option>
              <option value="status_1">有効</option>
            </select>
          </div>
          <div>
            <button onClick={()=>bulkOperationBtn()}>適用</button>
          </div>
        </div> */}
        <div className="right_group">
          <Paging
            length = {photographers.length}
            paged = {paged}
            showCount = {showCount}
            prevtext = {'前へ'}
            nexttext = {'次へ'}
            slug = {'photographers/list'}
          />
        </div>
      </section>
    </main>
  )
}

export default PhotographerList