/*
フロント側でZipファイルを2Mずつ分割アップロードする処理
Zipファイル以外をアップロード仕様としたとき、エラーを出してアップロードさせない。
一時保存先：uploads
*/ 
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiDir, JsDir } from "../../common";
import importScript from '../hooks/ImportScript';
import { changeLoading } from '../../reducks/pageInfos/operations'


const MAX_CHUNK_SIZE = 1024 * 1024; // 1MB
const allowedFileTypes = ["application/zip","application/x-7z-compressed","application/x-zip-compressed"]; // 許可されるファイルの種類（この場合はZIPのみ）

function UploadFile() {
  //ローディング
  const loading = useSelector(state => state.pageInfos.loading);
  const dispatch = useDispatch();
  const [fileError, setFileError] = useState(""); // 新しい状態を追加

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    // console.log(selectedFile);
    if (!selectedFile) {
      setFileError(""); // エラーがない場合はエラーメッセージをクリア
      return;
    }

    if (!allowedFileTypes.includes(selectedFile.type)) {
      setFileError("ZIPファイルのみアップロードが許可されています。");
    } else {
      setFileError("");
    }
  };

  const handleUpload = async () => {
    // event.preventDefault();

    if (fileError) {
      return; // エラーがある場合はアップロードを中止
    }

    try{
      /**
       * [Description for formatFileSize]
       * バイト単位で表されたファイルサイズをより読みやすい形式にフォーマットする
       * @param {*} size ファイルサイズ
       * @returns formattedSize + ' ' + unit　フォーマットされた値
       */
      function formatFileSize(size) {
        if (size === null || size === undefined) {
          return 'N/A'; // ファイルサイズが存在しない場合は 'N/A' を返す
        }
      
        const units = ['B', 'KB', 'MB', 'GB', 'TB']; // ファイルサイズの単位の配列
        let unitIndex = 0; // 単位の配列のインデックス
      
        while (size >= 1024 && unitIndex < units.length - 1) {
          size /= 1024;
          unitIndex++;
        }
      
        const formattedSize = unitIndex === 0 ? size.toFixed(0) : size.toFixed(2); // Bの場合は小数点以下を表示しないように修正
        const unit = units[unitIndex];
      
        return formattedSize + ' ' + unit;
      }

      const form = document.getElementById('form');
      form.addEventListener('submit', uploadFileHandler);
        
      /**
       * ファイルを分割して、フォームで送信、アップロードする処理
       * ※目安：1GBのZipファイルで約10分,3GBのZipファイルで約45分
       * ※端末メモリ使用量：約10GB
       */
      async function uploadFileHandler(e){
        e.preventDefault();

        //ローディング開始
        dispatch(changeLoading(true))
        
        const form = document.getElementById('form');
        const input = document.getElementById('file-input');
        const inputSize = document.getElementById('input-size'); //インプットサイズ
        const uploadSize = document.getElementById('upload-size'); //アップロード結合サイズ
        const progress = document.getElementById('progress'); //進捗

        const file = input.files[0];
        const name = `${file.name}`; //ファイル名
        const sliceSize = 2 * 1024 * 1024; // 切り取るサイズ 2MB

        inputSize.innerText = 'ファイルサイズ :' + formatFileSize(file.size); // ファイルサイズをGBで表示
        const count = Math.ceil(file.size / sliceSize);

        for (let k = 0; k < count; k++) {
          const start = k * sliceSize;
          const end = Math.min((k + 1) * sliceSize, file.size);
          const slice = file.slice(start, end);

          //フォームデータに追加
          const formData = new FormData();
          formData.append('file', slice);
          formData.append('name', name);
          formData.append('part', k + 1);
          formData.append('total', count);

          progress.innerText = '進捗 : ' + Math.ceil(((k + 1) / count) * 100).toString()+'%完了';

          // PHPファイルに渡す
          const response = await fetch(ApiDir+'/upload.php', {
            method: 'POST',
            body: formData
          });

          const result = await response.json();
          uploadSize.innerText = 'アップロード結合サイズ : ' + result.size; // アップロード結合したサイズを表示

          await new Promise((resolve) => setTimeout(resolve, 1000)); // 次のアップロードまで1秒待機
        }
        //ローディング開始
        dispatch(changeLoading(false))
      }
    }catch(error){
      console.error("uploadError;",error);
    }
    
  };

  return (
    <div>
      <form id="form" enctype="multipart/form-data" class="upload_form">
        <label htmlFor="file-input">
          <input 
          type="file" 
          name="file" 
          id="file-input" 
          accept="application/zip"
          onChange={handleFileChange} 
          required
          />
        </label>
        <button type="submit" onClick={handleUpload} disabled={fileError}>アップロード</button>
      </form>
      {fileError && <p className="error">{fileError}</p>}
      <p><span id="input-size"></span></p>
      <p><span id="upload-size"></span></p>
      <p><span id="progress"></span></p>
      {/* <script src="/js/upload.js"></script> */}
      {/* <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload</button>
      {progress > 0 && <progress value={progress} max="100" />} */}
      <div id="loading_area" className={loading===true?'':'hidden'}>
        <div className="loader">Loading...</div>
      </div>
    </div>
    
    
  );
}

export default UploadFile;
