import React, { useEffect, useCallback, useState } from "react"
import { selectOrderHistoryList } from '../../reducks/orders/operations'
import DocumentMeta from 'react-document-meta'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import { ImagesDir, RouteDir, ItemImageDir } from "../../common"
import { BreadCrumb } from '../../components'
import { Paging } from '../../components/UIkit'
import { SiteTitle } from './common'
import queryString from 'query-string';

const OrderHistory = (props) =>
{

  const dispatch = useDispatch()

  //ページング用 パラメータの取得
  const queryParam = queryString.parse(props.location.search)
  const [paged, setPaged] = useState('')
  const [showCount, setShowCount] = useState('')

  //購入履歴リスト
  const orderHistories = useSelector(state => state.orders.history)

  useEffect(()=>{

    //購入履歴の取得
    dispatch(selectOrderHistoryList())

    if(queryParam.paged)
    {
      setPaged(queryParam.paged)
    }
    else
    {
      setPaged(1)
    }
    setShowCount(10) //１ページに表示する数

  },[dispatch, queryParam.paged])



  const meta =
  {
    title: SiteTitle,
  }

  const Floors =
  [
    {
      name: '顧客編集',
      href: '/mypage/customer_edit'
    }
  ]

  return(
    <DocumentMeta {...meta}>
      <div id="customer_list_page">
        <BreadCrumb
          floors = { Floors }
        />
        <main className="member_edit_content form_type_1">
          <h1>顧客編集</h1>
          <section>
          <form encType="multipart/form-data" method="post" id="update_form" onSubmit={(e)=>e.preventDefault()}>
          <p className="customer_name">カメラマン名</p>
            <table>
              <thead></thead>
              <tbody>
                <tr>
                  <th>氏名<span className="required">必須</span></th>
                  <td>
                    <div className="family_name_area">
                      <span>姓</span>
                      <input
                        type = "text"
                        name = {'family_name'}
                      />
                    </div>
                    <div className="first_name_area">
                      <span>名</span>
                      <input
                        type = "text"
                        name = {'first_name'}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>フリガナ<span className="required">必須</span></th>
                  <td>
                    <div className="family_name_area">
                      <span>セイ</span>
                      <input
                        type = "text"
                        name = {'family_name_furigana'}
                      />
                    </div>
                    <div className="first_name_area">
                      <span>メイ</span>
                      <input
                        type = "text"
                        name = {'first_name_furigana'}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>撮影日<span span className="required">必須</span></th>
                  <td>
                    <div className="family_name_area">
                      <span></span>
                    <input
                        type = "text"
                        name = {'first_name_furigana'}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>顧客ページURL</th>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </form>
          <div className="button_area">
          <button id = "send_btn" className="desabled">画像を追加</button>
          </div>
          <div className="media_area">
            <div className="media_box">
              <img src={process.env.PUBLIC_URL + '/images/top_introduce_image.jpg'} />
              <div className="delete_btn">×</div>
            </div>
            <div className="media_box">
              <img src={process.env.PUBLIC_URL + '/images/top_introduce_image.jpg'} />
              <div className="delete_btn">×</div>
            </div>
            <div className="media_box">
              <img src={process.env.PUBLIC_URL + '/images/top_introduce_image.jpg'} />
              <div className="delete_btn">×</div>
            </div>
            <div className="media_box">
              <img src={process.env.PUBLIC_URL + '/images/top_introduce_image.jpg'} />
              <div className="delete_btn">×</div>
            </div>
            <div className="media_box">
              <img src={process.env.PUBLIC_URL + '/images/top_introduce_image.jpg'} />
              <div className="delete_btn">×</div>
            </div>
            <div className="media_box">
              <img src={process.env.PUBLIC_URL + '/images/top_introduce_image.jpg'} />
              <div className="delete_btn">×</div>
            </div>
          </div>
          </section>
          <section className="under_menu_area">
            <div className="left_group">
            </div>
            <div className="right_group">
              <Paging
              publicPage = {true}
                length = {orderHistories.length}
                paged = {paged}
                showCount = {showCount}
                prevtext = {'前へ'}
                nexttext = {'次へ'}
                slug = {'mypage/order_history'}
              />
            </div>
          </section>
          <div className="button_area">
            <button id="registration_btn" className="desabled">登　録</button>
          </div>
        </main>
      </div>
    </DocumentMeta>
  )
}

export default OrderHistory