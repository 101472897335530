import React, { useCallback, useEffect, useState } from "react"
import { selectMemberImage, updateMember, deleteImage } from '../../reducks/members/operations'
import { changeLoading } from '../../reducks/pageInfos/operations'
import  {ImageSelectyButton, ImageList, UploadFile} from '../../components/UIkit'
import { useDispatch,useSelector } from 'react-redux'
import axios from 'axios'
import { ApiDir, JsDir, RouteDir, PhotoImageDir, HomeUrl } from '../../common'
import importScript from '../../components/hooks/ImportScript'


const ItemList = (props) =>
{
  // svg操作のjsの読み込み
  importScript(JsDir + '/ajaxzip3.js')

  //ローディング
  const loading = useSelector(state => state.pageInfos.loading)

  const pageMemberId = props.match.params.id
  const dispatch = useDispatch()
  const [photographer, setPhotographerId] = useState([])
  const imageSrc = useSelector(state => state.members.imageSrc)
  const imageId = useSelector(state => state.members.imageId)
  const imageSrcArray = useSelector(state => state.members.imageSrcArray)
  const imageIdArray = useSelector(state => state.members.imageIdArray)
  const [image, setImage] = useState([]);

  const [selectImageDeleteId, setSelectImageDeleteId] = useState([]);
  const [selectImageDeleteFlag, setSelectImageDeleteFlag] = useState(false);

  //姓の入力
  const [familyNameValue, setFamilyNameValue] = useState('')
  const inputFamilyNameValue = useCallback((event) =>
  {
    inputValueCheck()
    setFamilyNameValue(event.target.value)
  }, [setFamilyNameValue])

  //名の入力
  const [firstNameValue, setFirstNameValue] = useState('')
  const inputFirstNameValue = useCallback((event) =>
  {
    inputValueCheck()
    setFirstNameValue(event.target.value)
  }, [setFirstNameValue])

  //セイの入力
  const [familyNameFuriganaValue, setFamilyNameFuriganaValue] = useState('')
  const inputFamilyNameFuriganaValue = useCallback((event) =>
  {
    inputValueCheck()
    setFamilyNameFuriganaValue(event.target.value)
  }, [setFamilyNameFuriganaValue])

  //メイの入力
  const [firstNameFuriganaValue, setFirstNameFuriganaValue] = useState('')
  const inputFirstNameFuriganaValue = useCallback((event) =>
  {
    inputValueCheck()
    setFirstNameFuriganaValue(event.target.value)
  }, [setFirstNameFuriganaValue])

  //撮影日の入力
  const [ShootingDateValue, setShootingDateValue] = useState('')
  const inputShootingDateValue = useCallback((event) =>
  {
    inputValueCheck()
    setShootingDateValue(event.target.value)
  }, [setShootingDateValue])

  //生年月日の入力
  const [birthdayValue, setBirthdayValue] = useState('')
  const inputBirthdayValue = useCallback((event) =>
  {
    setBirthdayValue(event.target.value)
  }, [setBirthdayValue])

  //郵便番号の入力
  const [postalCodeValue, setPostalCodeValue] = useState('')
  const inputPostalCodeValue = useCallback((event) =>
  {
    setPostalCodeValue(event.target.value)
  }, [setPostalCodeValue])

  //住所の入力
  const [addressValue, setAddressValue] = useState('')
  const inputAddressValue = useCallback((event) =>
  {
    setAddressValue(event.target.value)
  }, [setAddressValue])

  //電話番号の入力
  const [telnumberValue, setTelnumberValue] = useState('')
  const inputTelnumberValue = useCallback((event) =>
  {
    setTelnumberValue(event.target.value)
  }, [setTelnumberValue])

  //メールアドレスの入力
  const [mailAddressValue, setMailAddressValue] = useState('')
  const inputMailAddressValue = useCallback((event) =>
  {
    setMailAddressValue(event.target.value)
  }, [setMailAddressValue])

  //メルマガの受信の入力
  const [mailMagazineFlagValue, setMailMagazineFlagValue] = useState('1')
  const inputMailMagazineFlagValue = useCallback((event) =>
  {
    setMailMagazineFlagValue(event.target.value)
  }, [setMailMagazineFlagValue])

  //カメラマン名の入力
  const [PhotographerIdValue, setPhotographerIdValue] = useState('')
  const inputPhotographerIdValue = useCallback((event) =>
  {
    setPhotographerIdValue(event.target.value)
  }, [setPhotographerIdValue])

  //URLの入力
  const [MembersUrlValue, setMembersUrlValue] = useState('')
  const inputMembersUrlValue = useCallback((event) =>
  {
    inputValueCheck()
    setMembersUrlValue(event.target.value)
  }, [setMembersUrlValue])

  //ステータスの入力
  const [StatusValue, setStatusValue] = useState('1')
  const inputStatusValue = useCallback((event) =>
  {
    inputValueCheck()
    setStatusValue(event.target.value)
  }, [setStatusValue])

  //カメラマン名の入力
  const [ImageIdValue, setImageIdValue] = useState('')
  const inputImageIdValue = useCallback((event) =>
  {
    setImageIdValue(event.target.value)
  }, [setImageIdValue])


  //入力値が入っているかの確認
  const inputValueCheck = () =>
  {
    // const inputValueOfFamilyName = document.getElementsByName('family_name')
    // const inputValueOfFirstName = document.getElementsByName('first_name')
    // const inputValueOfFamilyNameFurigana = document.getElementsByName('family_name_furigana')
    // const inputValueOfFirstNameFurigana = document.getElementsByName('first_name_furigana')
    // const inputShootingDateValue = document.getElementsByName('shooting_date')
    // const inputMembersUrlValue = document.getElementsByName('members_url')

    // if(inputValueOfFamilyName[0].value !== '' && inputValueOfFirstName[0].value !== '' && inputValueOfFamilyNameFurigana[0].value !== '' && inputValueOfFirstNameFurigana[0].value !== '' && inputShootingDateValue[0].value !== '' && inputMembersUrlValue[0].value !== '')
    // {
    //   document.getElementById('insert_btn').classList.remove('desabled')
    // }
    // else
    // {
    //   document.getElementById('insert_btn').classList.add('desabled')
    // }
    const formElem = document.getElementById('update_form');
    for (let i = 0; i < formElem.elements.length; i++) {
      const elem = formElem.elements[i];
      if (elem.tagName === 'INPUT') {
        /* inputタグのみチェック */
        if (elem.hasAttribute('required')) {
          if (elem.value.trim() == '') {
            document.getElementById('insert_btn').classList.add('desabled');
            return;
          }
        }
      }
    }
    document.getElementById('insert_btn').classList.remove('desabled');
  }

  useEffect(()=>
  {
    let params = new URLSearchParams();

    //会員情報の取得
    params = new URLSearchParams();
    params.append('member_id',pageMemberId);
    params.append('formkey','selectkey');
    axios.post(ApiDir+'/selectMemberListEdit.php',params)
    .then(function(response){
      // console.log(response.data)
      setFamilyNameValue(response.data[0].family_name)
      setFirstNameValue(response.data[0].first_name)
      setFamilyNameFuriganaValue(response.data[0].family_name_furigana)
      setFirstNameFuriganaValue(response.data[0].first_name_furigana)
      setShootingDateValue(response.data[0].shooting_date)
      setPhotographerIdValue(response.data[0].photographer_id)
      setMembersUrlValue(response.data[0].members_url)
      setImageIdValue(response.data[0].image_id)
      setStatusValue(response.data[0].status)
      inputValueCheck()
    })
    .catch(function(error){
      console.log(error)
      return
    })

    //カメラマン名取得
    params = new URLSearchParams();
    params.append('formkey','selectkey');
    axios.post(ApiDir+'/selectPhotographerList.php',params)
    .then(function(response){
      // console.log(response.data)
      setPhotographerId(response.data)
    })
    .catch(function(error){
      console.log(error)
      return
    })

    //画像取得
    params = new URLSearchParams();
    params.append('member_id',pageMemberId);
    params.append('formkey','selectkey');
    axios.post(ApiDir+'/selectMemberImage.php',params)
    .then(function(response){
      console.log(response.data)
      setImage(response.data)
    })
    .catch(function(error){
      console.log(error)
      return
    })

  },[pageMemberId])

  //登録ボタン押下時の処理
  const sendFormData = () =>
  {
   
    //ローディング開始
    dispatch(changeLoading(true))

    //form情報の取得
    const formElement = document.getElementById('update_form')
    const formData = new FormData(formElement);

    //商品IDの追加
    formData.append('member_id',pageMemberId)

    // //画像削除情報の追加
    // formData.append('delete_image_flag',selectImageDeleteFlag)
    // formData.append('delete_image_id',JSON.stringify(selectImageDeleteId))

    //formkeyの追加
    formData.append('member_id',pageMemberId);
    formData.append('status',StatusValue);
    formData.append('formkey','updatekey')
    dispatch(updateMember(formData))
  }

  //住所自動入力
  const {AjaxZip3} = window
  const postalAutoInput = ()=>
  {
    AjaxZip3.zip2addr('postal_code','','address','address')
    setTimeout(()=>{
      const addressElement = document.getElementsByName('address')
      const addressValue = addressElement[0].value
      setAddressValue(addressValue)
    },100)
  }

  // 削除処理 add 20220916
  const remove = (index,e,optionId) => 
  {
    const ret =window.confirm('この画像を削除しますか？');
    if(!ret){
      return false
    }else{
      image.splice(index, 1);
      setImage([...image]);
      dispatch(deleteImage(optionId))
    }
    
  };

  return(
    <main id="member_edit_page">
      <h2 className="page_title"><span>顧客編集</span></h2>
      <section className="box_type_1">
        <div className="title_area">
          <h3>顧客情報</h3>
        </div>
        <div className="content_area">
          <form encType="multipart/form-data" method="post" id="update_form" onSubmit={(e)=>e.preventDefault()}>
          <table className={pageMemberId}>
              <thead></thead>
              <tbody>
                <tr>
                  <th>氏名<span className="required">必須</span></th>
                  <td className="flex">
                    <span className="header">姓</span>
                    <input
                      type = "text"
                      name = {'family_name'}
                      className = ""
                      value = {familyNameValue}
                      onChange = {inputFamilyNameValue}
                      required
                    />
                    <br/>
                    <span className="header">名</span>
                    <input
                      type = "text"
                      name = {'first_name'}
                      value = {firstNameValue}
                      onChange = {inputFirstNameValue}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <th>フリガナ<span className="required">必須</span></th>
                  <td className="flex">
                  <span className="header">セイ</span>
                    <input
                      type = "text"
                      name = {'family_name_furigana'}
                      value = {familyNameFuriganaValue}
                      onChange = {inputFamilyNameFuriganaValue}
                      required
                    />
                    <br/>
                    <span className="header">メイ</span>
                    <input
                      type = "text"
                      name = {'first_name_furigana'}
                      value = {firstNameFuriganaValue}
                      onChange = {inputFirstNameFuriganaValue}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <th>撮影日<span className="required">必須</span></th>
                  <td>
                    <input
                      type = "text"
                      name = {'shooting_date'}
                      value = {ShootingDateValue}
                      onChange = {inputShootingDateValue}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <th>カメラマン名</th>
                  <td className="birthday_box">
                  <select
                      name = {'photographer_id'}
                      value = {PhotographerIdValue}
                      onChange = {inputPhotographerIdValue}
                    >
                      {Array.isArray(photographer) && photographer.map((option) => (
                      <option key={option.id} value={option.id}>{option.name}</option>
                    ))}
                    </select>
                  </td>
                </tr>
                {/* <tr>
                  <th>画像ファイル【Zip形式】</th>
                  <td>
                    <input 
                      type="file" 
                      name="newzip"
                    />
                  </td>
                </tr> */}
                <tr>
                  <th>画像ファイル<br></br><span class="small red">※Zip形式のみ</span></th>
                  <td>
                    {/* <ImageSelectyButton/> */}
                    <UploadFile/>
                    <div className="upload_box_state">
                    {Array.isArray(image) && image.map((option, index) => (
                      <div className="preview" key={option.id}>
                        <button 
                          id={'image_'+option.id}
                          className="image_delete"
                          onClick={(e)=>{remove(index,e,option.id);}}
                        ></button>
                        <div>
                        {/* <img src={PhotoImageDir+'/'+option.member_id+option.path}></img> */}
                        <img id={option.id + '_img'} src={ApiDir+'/image.php?img=/'+option.member_id+option.path} ></img>
                        <p>{option.path.substr(1)}</p>
                        </div>
                      </div>
                    ))}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>URL</th>
                  <td>
                    <span>{HomeUrl + 'download/'}</span>
                    <input
                      type = "text"
                      name = {'members_url'}
                      value = {MembersUrlValue}
                      onChange = {inputMembersUrlValue}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <th>状態</th>
                  <td className="mail_magazine_flag_box">
                  <label className="radio_btn_label">
                    <input
                      type="radio"
                      name="status"
                      value={1}
                      checked = {StatusValue === '1'}
                      onChange = {inputStatusValue}
                    />下書き
                  </label>
                  <br/>
                  <label className="radio_btn_label">
                    <input
                      type="radio"
                      name="status"
                      value={0}
                      checked = {StatusValue === '0'}
                      onChange = {inputStatusValue}
                    />公開
                  </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
        <div className="button_area">
          <button id = "insert_btn" className="desabled" onClick={()=>sendFormData()}>登録</button>
        </div>
      </section>
      <div id="loading_area" className={loading===true?'':'hidden'}>
        <div className="loader">Loading...</div>
      </div>
    </main>
  )
}

export default ItemList